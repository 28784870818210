import { formatError } from "../../utils/format-error.js";
import { uniqueId } from "../../utils/unique-id.js";
import { isTextResponse } from "./is-text-response.js";
import { parseRequestBody, parseTextBody } from "./parse-request-body.js";
const responseToResponseBody = async (response) => {
    const contentTypeHeader = response.headers.get("content-type");
    if (isTextResponse(contentTypeHeader)) {
        const rawText = await response.text();
        const body = parseTextBody(rawText);
        return { body, raw: rawText };
    }
    const blob = await response.blob();
    return {
        body: { type: "other", size: blob.size },
        raw: blob,
    };
};
const formatHeaders = (headers) => {
    const result = {};
    headers?.forEach((value, key) => {
        result[key] = value;
    });
    return result;
};
/**
 * Patch the global `Request` so we can access arguments like 'body' directly
 */
export const patchRequest = () => {
    const NativeRequest = window.Request;
    class PatchedRequest extends NativeRequest {
        args;
        constructor(input, init) {
            super(input, init);
            this.args = {
                init: init || {},
            };
            if (input instanceof PatchedRequest) {
                this.args.init = { ...input.args.init, ...this.args.init };
            }
            this.clone = function clone() {
                return new PatchedRequest(input, init);
            };
        }
    }
    window.Request = PatchedRequest;
    return () => {
        window.Request = NativeRequest;
    };
};
export const patchFetch = (recorder) => {
    const originalFetch = window.fetch;
    window.fetch = (input, init) => {
        const request = input instanceof Request ? input.clone() : new Request(input, init);
        let requestBody = undefined;
        try {
            requestBody = parseRequestBody(request.args.init.body);
        }
        catch (error) {
            recorder.recordInternalError(error);
        }
        const event = {
            id: uniqueId(),
            type: "request",
            initiator: "fetch",
            time: Date.now(),
            url: request.url,
            startTime: performance.now(),
            request: {
                method: request.method,
                headers: formatHeaders(request.headers),
                body: requestBody,
            },
            extra: {
                request: {
                    body: request.args.init.body ?? undefined,
                },
            },
        };
        return originalFetch(input, init)
            .then(async (_response) => {
            try {
                const response = _response.clone();
                event.response = {
                    status: response.status,
                    headers: formatHeaders(response.headers),
                };
                event.endTime = performance.now();
                const { body, raw } = await responseToResponseBody(response);
                event.response = {
                    ...event.response,
                    body,
                };
                event.extra.response = {
                    body: raw,
                };
            }
            catch (error) {
                recorder.recordInternalError(error);
            }
            recorder.recordEvent(event);
            return _response;
        })
            .catch((fetchError) => {
            try {
                event.endTime = performance.now();
                event.error = formatError(fetchError);
                if (fetchError instanceof Error) {
                    event.extra.error = fetchError;
                }
            }
            catch (error) {
                recorder.recordInternalError(error);
            }
            recorder.recordEvent(event);
            throw fetchError;
        });
    };
    return () => {
        window.fetch = originalFetch;
    };
};
