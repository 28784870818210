import { PageLayoutSkeleton } from "../page-layout/page-layout.skeleton.js";

import { IntegrationsCardSkeleton } from "./IntegrationsCardSkeleton.js";
import * as styles from "./IntegrationsHome.styles";

export const IntegrationsHomeSkeleton = () => {
  return (
    <PageLayoutSkeleton title="Integrations">
      <div className={styles.grid}>
        <IntegrationsCardSkeleton />
        <IntegrationsCardSkeleton />
        <IntegrationsCardSkeleton />
        <IntegrationsCardSkeleton />
        <IntegrationsCardSkeleton />
      </div>
    </PageLayoutSkeleton>
  );
};
