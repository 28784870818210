export const parseTextBody = (body) => {
    return {
        type: "text",
        text: body,
    };
};
const parseFile = (file) => ({
    type: "file",
    name: file.name,
    size: file.size,
    mimeType: file.type,
});
const parseFormData = (body) => {
    const output = {
        type: "form-data",
        data: {},
    };
    body.forEach((value, key) => {
        if (value instanceof File) {
            output.data[key] = parseFile(value);
        }
        else if (typeof value === "string") {
            output.data[key] = value;
        }
    });
    return output;
};
const parseURLSearchParams = (body) => {
    const output = {
        type: "url-search-params",
        data: {},
    };
    body.forEach((value, key) => {
        output.data[key] = value;
    });
    return output;
};
const parseBlob = (blob) => ({
    type: "blob",
    size: blob.size,
    mimeType: blob.type,
});
const parseBuffer = (buffer) => ({
    type: "array-buffer",
    byteLength: buffer.byteLength,
});
export const parseRequestBody = (body) => {
    try {
        if (typeof body === "string") {
            return parseTextBody(body);
        }
        if (body instanceof FormData) {
            return parseFormData(body);
        }
        if (body instanceof URLSearchParams) {
            return parseURLSearchParams(body);
        }
        if (body instanceof Blob) {
            return parseBlob(body);
        }
        if (body instanceof ArrayBuffer) {
            return parseBuffer(body);
        }
        return {
            type: "unknown",
        };
    }
    catch (e) {
        return undefined;
    }
};
