import { formatError } from "../utils/format-error.js";
import { originalWarn } from "../utils/original-log.js";
import { uniqueId } from "../utils/unique-id.js";
import { ConsoleEventRecorder } from "./console-events/console-event-recorder.js";
import { InteractionEventRecorder } from "./interaction-events/interaction-event-recorder.js";
import { NetworkEventRecorder } from "./network-events/network-event-recorder.js";
export class SessionEventRecorder {
    internalErrors = [];
    recorders = [];
    events = [];
    constructor({ startImmediately, privacyFunctions, eventRecorders, }) {
        this.recorders = eventRecorders ?? [
            new NetworkEventRecorder({ privacyFunctions }),
            new ConsoleEventRecorder({ privacyFunctions }),
            new InteractionEventRecorder({ privacyFunctions }),
        ];
        if (startImmediately) {
            this.start();
        }
    }
    start() {
        try {
            this.recorders.forEach((recorder) => {
                recorder.on("event", this.recordEvent);
                recorder.on("internal-error", this.recordInternalError);
                recorder.start();
            });
        }
        catch (error) {
            originalWarn(`capture.dev encountered an issue: ${error}`);
        }
    }
    stop() {
        this.recorders.forEach((recorder) => {
            recorder.off("event", this.recordEvent);
            recorder.off("internal-error", this.recordInternalError);
            recorder.stop();
        });
    }
    getInternalErrors() {
        return this.internalErrors
            .map((error) => (error instanceof Error ? formatError(error) : null))
            .filter((error) => error !== null);
    }
    recordEvent = (event) => {
        this.events.push(event);
        this.trimEvents();
    };
    recordInternalError = (error) => {
        this.internalErrors.push(error);
    };
    getCapturedEvents() {
        return this.events;
    }
    startRecording() {
        this.events = [];
        this.events.push({
            id: uniqueId(),
            time: Date.now(),
            type: "recording-start",
        });
    }
    stopRecording() {
        this.trimEvents();
        this.events.push({
            id: uniqueId(),
            time: Date.now(),
            type: "recording-stop",
        });
    }
    recordSnapshotTaken() {
        this.events.push({
            id: uniqueId(),
            time: Date.now(),
            type: "snapshot",
        });
    }
    removeEvent(id) {
        for (let i = this.events.length - 1; i >= 0; i--) {
            if (this.events[i].id === id) {
                this.events.splice(i, 1);
                break;
            }
        }
    }
    clearTriggerKeyEvents() {
        const clearedKeys = ["Ctrl", "Meta", "/"];
        const lastFive = this.events.slice(-5);
        lastFive.forEach((event) => {
            if (event.type === "keypress") {
                const matchingKeyIndex = clearedKeys.findIndex((key) => key === event.key);
                if (matchingKeyIndex !== -1) {
                    clearedKeys.splice(matchingKeyIndex, 1);
                    this.removeEvent(event.id);
                }
            }
        });
    }
    trimEvents = () => {
        const maxTime = Date.now() - 60 * 1000 * 5; // Only store the last 5 minutes of events
        let eventsToTrim = 0;
        for (let i = 0; i < this.events.length; i++) {
            if (this.events[i].time < maxTime) {
                eventsToTrim += 1;
            }
            else {
                break;
            }
        }
        if (eventsToTrim > 0) {
            this.events = this.events.slice(eventsToTrim);
        }
    };
}
