import { Global, css } from "@emotion/react";

import { darkColors } from "./colors/dark.js";
import { lightColors } from "./colors/light.js";
import { color, colorToVar, ThemeColors, typography } from "./constants.js";
import figtree400 from "./fonts/figtree-latin-400-normal.woff";
import figtree700 from "./fonts/figtree-latin-700-normal.woff";
import funnelBold from "./fonts/FunnelDisplay-Bold.woff2";
import jetbrainsMonoRegular from "./fonts/JetBrainsMono-Regular.woff2";

const global = css`
  html {
    color-scheme: light dark; // Required for iframe background to be transparent in widget
  }

  @font-face {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 400;
    src: url(${figtree400}) format("woff2");
  }

  @font-face {
    font-family: "Figtree";
    font-style: normal;
    font-weight: 700;
    src: url(${figtree700}) format("woff2");
  }

  @font-face {
    font-family: "JetBrains Mono";
    font-style: normal;
    font-weight: 400;
    src: url(${jetbrainsMonoRegular}) format("woff2");
  }

  @font-face {
    font-family: "Funnel Display";
    font-style: normal;
    font-weight: 700;
    src: url(${funnelBold}) format("woff2");
  }

  :root {
    --font-family-heading: "Funnel Display", sans-serif;
    --font-family-default: "Figtree", sans-serif;
    --font-family-mono: "JetBrains Mono", monospace;
  }

  #root,
  html {
    height: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    font: ${typography.body3};
  }

  *:focus {
    outline: none;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  button {
    border: none;
    background: none;
    font: inherit;
    color: inherit;
    padding: 0;
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  ul,
  li,
  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }

  p + p {
    margin-top: 1em;
  }

  h1 {
    font: ${typography.heading6};
  }

  h2 {
    font: ${typography.heading5};
  }

  h3 {
    font: ${typography.heading4};
  }

  h4 {
    font: ${typography.heading3};
  }

  hgroup {
    p {
      margin-top: 0.5em;
      color: ${color("textSecondary")};
    }
    h2 + p {
      font: ${typography.body4};
    }
  }

  .light {
    ${Object.entries(lightColors).map(([key, value]) => {
      return `${colorToVar(key as keyof ThemeColors)}: ${value};`;
    })}
    color: ${color("textPrimary")};
  }

  .dark {
    ${Object.entries(darkColors).map(([key, value]) => {
      return `${colorToVar(key as keyof ThemeColors)}: ${value};`;
    })}
    color: ${color("textPrimary")};
  }

  @media (prefers-color-scheme: light) {
    body:not(.dark) {
      ${Object.entries(lightColors).map(([key, value]) => {
        return `${colorToVar(key as keyof ThemeColors)}: ${value};`;
      })}
    }
  }

  @media (prefers-color-scheme: dark) {
    body:not(.light) {
      ${Object.entries(darkColors).map(([key, value]) => {
        return `${colorToVar(key as keyof ThemeColors)}: ${value};`;
      })}
    }
  }

  body {
    color: ${color("textPrimary")};
    background: ${color("secondary")};
  }
`;

export const GlobalStyles = () => {
  return <Global styles={global} />;
};
