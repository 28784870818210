import { formatError } from "../../utils/format-error.js";
import { originalLog } from "../../utils/original-log.js";
import { toJSON } from "../../utils/to-json.js";
import { uniqueId } from "../../utils/unique-id.js";
import { EventRecorder } from "../event-recorder.js";
const consoleMethods = ["log", "info", "warn", "error", "debug"];
const parseError = (error) => {
    return {
        __CAPTURE_TYPE__: "error",
        error: formatError(error),
    };
};
export class ConsoleEventRecorder extends EventRecorder {
    unsetters = [];
    start() {
        this.unsetters = [];
        for (const method of consoleMethods) {
            const recordEvent = this.recordEvent;
            const original = console[method];
            console[method] = function (...args) {
                recordEvent({
                    id: uniqueId(),
                    type: "console",
                    time: Date.now(),
                    method,
                    extra: {
                        originalArgs: args,
                    },
                    args: args.map((arg) => {
                        if (arg instanceof Error) {
                            return parseError(arg);
                        }
                        return toJSON(arg);
                    }),
                });
                return original(...args);
            };
            this.unsetters.push(() => {
                console[method] = original;
            });
        }
        window.addEventListener("error", this.onErrorHandler);
        window.addEventListener("unhandledrejection", this.onPromiseRejectionHandler);
        window.addEventListener("messageerror", this.onMessageErrorHandler);
        window.addEventListener("securitypolicyviolation", this.onSecurityPolicyViolationHandler);
    }
    onErrorHandler = (event) => {
        this.recordEvent({
            id: uniqueId(),
            time: Date.now(),
            type: "console",
            method: "error",
            args: [
                event.error instanceof Error
                    ? parseError(event.error)
                    : toJSON(event.error),
            ],
            extra: {
                originalArgs: [event.error],
            },
        });
    };
    onPromiseRejectionHandler = (event) => {
        originalLog(event);
    };
    onMessageErrorHandler = (event) => {
        originalLog(event);
    };
    onSecurityPolicyViolationHandler = (event) => {
        const args = [
            `Refused to load the resource '${event.blockedURI}' because it violates the following Content Security Policy directive: '${event.violatedDirective}'.`,
        ];
        this.recordEvent({
            id: uniqueId(),
            time: Date.now(),
            type: "console",
            method: "error",
            args,
            extra: {
                originalArgs: args,
            },
        });
    };
    stop() {
        window.removeEventListener("error", this.onErrorHandler);
        window.removeEventListener("unhandledrejection", this.onPromiseRejectionHandler);
        window.removeEventListener("messageerror", this.onMessageErrorHandler);
        window.removeEventListener("securitypolicyviolation", this.onSecurityPolicyViolationHandler);
        this.unsetters.forEach((unsetter) => unsetter());
    }
}
