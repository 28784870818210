/* eslint-disable @typescript-eslint/no-explicit-any */
import { z } from "zod";

/* We have a depth limit here to prevent typescript errors about excessive recursion.
/* @see https://stackoverflow.com/questions/76310959/recursive-type-reports-type-instantiation-is-excessively-deep-and-possibly-infi/76315151#76315151
*/
export type JSONValue<D extends number = 9, DA extends any[] = []> =
  | string
  | number
  | boolean
  | null
  | (D extends DA["length"]
      ? any
      : { [key: string]: JSONValue<D, [0, ...DA]> | undefined })
  | (D extends DA["length"] ? any : JSONValue<D, [0, ...DA]>[]);

export const JSONValue: z.ZodType<JSONValue> = z.lazy(() =>
  z.union([
    z.string(),
    z.number(),
    z.boolean(),
    z.null(),
    z.array(JSONValue),
    z.record(JSONValue),
  ])
);
