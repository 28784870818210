import { z } from "zod";

import { BaseEvent } from "./base-event.js";

export const ElementInfo = z.object({
  tagName: z.string(),
  name: z.string().optional(),
  attributes: z.record(z.string()),
});
export type ElementInfo = z.infer<typeof ElementInfo>;

export const ResizeEvent = BaseEvent.extend({
  type: z.literal("resize"),
  width: z.number(),
  height: z.number(),
});
export type ResizeEvent = z.infer<typeof ResizeEvent>;

export const ClickEvent = BaseEvent.extend({
  type: z.literal("click"),
  element: ElementInfo,
  pointer: z.string(),
});
export type ClickEvent = z.infer<typeof ClickEvent>;

export const FocusEvent = BaseEvent.extend({
  type: z.literal("focus"),
  element: ElementInfo,
});
export type FocusEvent = z.infer<typeof FocusEvent>;

export const KeyPressEvent = BaseEvent.extend({
  type: z.literal("keypress"),
  key: z.string(),
  meta: z.boolean().optional(),
  ctrl: z.boolean().optional(),
  shift: z.boolean().optional(),
  alt: z.boolean().optional(),
});
export type KeyPressEvent = z.infer<typeof KeyPressEvent>;

export const ChangeEvent = BaseEvent.extend({
  type: z.literal("change"),
  value: z.string(),
  element: ElementInfo,
  isTruncated: z.boolean().optional(),
});
export type ChangeEvent = z.infer<typeof ChangeEvent>;

export const NavigationEvent = BaseEvent.extend({
  type: z.literal("navigation"),
  url: z.string(),
});
export type NavigationEvent = z.infer<typeof NavigationEvent>;

export const SubmitEvent = BaseEvent.extend({
  type: z.literal("submit"),
  element: ElementInfo,
});
export type SubmitEvent = z.infer<typeof SubmitEvent>;

export const RecordingStartEvent = BaseEvent.extend({
  type: z.literal("recording-start"),
});
export type RecordingStartEvent = z.infer<typeof RecordingStartEvent>;

export const RecordingStopEvent = BaseEvent.extend({
  type: z.literal("recording-stop"),
});
export type RecordingStopEvent = z.infer<typeof RecordingStopEvent>;

export const SnapshotEvent = BaseEvent.extend({
  type: z.literal("snapshot"),
});
export type SnapshotEvent = z.infer<typeof SnapshotEvent>;

export const OpenWidgetEvent = BaseEvent.extend({
  type: z.literal("open-widget"),
});
export type OpenWidgetEvent = z.infer<typeof OpenWidgetEvent>;

export const CloseWidgetEvent = BaseEvent.extend({
  type: z.literal("close-widget"),
});
export type CloseWidgetEvent = z.infer<typeof CloseWidgetEvent>;

export const InteractionEvent = z.union([
  ResizeEvent,
  ClickEvent,
  FocusEvent,
  KeyPressEvent,
  ChangeEvent,
  NavigationEvent,
  SubmitEvent,
  RecordingStartEvent,
  RecordingStopEvent,
  SnapshotEvent,
  OpenWidgetEvent,
  CloseWidgetEvent,
]);
export type InteractionEvent = z.infer<typeof InteractionEvent>;

export const isInteractionEvent = (
  event: unknown
): event is InteractionEvent => {
  return (
    typeof event === "object" &&
    event !== null &&
    "type" in event &&
    typeof event["type"] === "string" &&
    [
      "resize",
      "click",
      "focus",
      "keypress",
      "change",
      "navigation",
      "submit",
    ].includes(event["type"])
  );
};
