import { isPlainObject } from "../../utils/is-plain-object.js";
import { isTextResponse } from "./is-text-response.js";
import { parseTextBody } from "./parse-request-body.js";
export const parseResponseBody = async (response, contentTypeHeader) => {
    if (typeof response === "string") {
        return parseTextBody(response);
    }
    else if (isPlainObject(response)) {
        return parseTextBody(JSON.stringify(response));
    }
    else if (isTextResponse(contentTypeHeader) && response instanceof Blob) {
        const text = await response.text();
        return parseTextBody(text);
    }
    else if (response instanceof Blob) {
        return {
            type: "other",
            size: response.size,
        };
    }
    else if (response instanceof ArrayBuffer) {
        return {
            type: "other",
            size: response.byteLength,
        };
    }
    return {
        type: "other",
    };
};
