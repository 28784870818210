import CaptureClient from "@capture-dev/browser";

import { config } from "../config.js";

export const captureClient = new CaptureClient({
  widgetBaseUrl: `${config.WEBAPP_ORIGIN}/widget`,
  captureKey: config.CAPTURE_KEY,
  isEnabled: () => {
    return window.location.pathname.startsWith("/widget") === false;
  },
});
