import { scale, hex } from "chroma-js";

const tint = "#2e6ffc";

const greyScale = scale(["#ffffff", "#111111"]);

const primary = greyScale(0);
const secondary = greyScale(0.02).mix(tint, 0.01);
const tertiary = greyScale(0.04).mix(tint, 0.03);
const contrast = greyScale(1);

const accent = "#BDEE63";
const complementary = hex("#2e6ffc");

const muted = greyScale(0.2).mix(tint, 0.1).alpha(0.2);
const overlay = "#00000080";

const backgroundSkeleton = "#e8ebf0";

const success = hex("#3E9B4F");
const warning = hex("#f59e0c");
const critical = hex("#dc2625");

const textWarning = hex("#d9760c");

export const lightColors = {
  primary: primary.hex(),
  primaryHover: primary.darken(0.1).hex(),
  primaryBorder: primary.darken(0.4).hex(),
  secondary: secondary.hex(),
  secondaryHover: secondary.darken(0.2).hex(),
  tertiary: tertiary.hex(),
  tertiaryHover: tertiary.darken(0.01).hex(),
  secondaryBorder: secondary.darken(0.4).hex(),
  muted: muted.hex(),
  overlay,
  textPrimary: greyScale(1).hex(),
  textSecondary: greyScale(0.6).mix(tint, 0.1).hex(),
  textInverse: greyScale(0).hex(),
  textInverseSecondary: greyScale(0.2).hex(),
  textPlaceholder: greyScale(0.4).mix(tint, 0.1).hex(),
  textDisabled: greyScale(0.4).hex(),
  accent,
  complementary: complementary.hex(),
  complementaryHover: complementary.brighten(0.2).hex(),
  success: success.hex(),
  textOnSuccess: "#ffffff",
  successMuted: success.alpha(0.1).hex(),
  textSuccess: success.darken(0.8).hex(),
  contrast: contrast.hex(),
  contrastHover: contrast.brighten(0.4).hex(),
  backgroundSkeleton,
  warning: warning.hex(),
  textWarning: textWarning.hex(),
  textWarningSecondary: textWarning.brighten(0.8).hex(),
  critical: critical.hex(),
  textOnCritical: "#ffffff",
  criticalMuted: critical.alpha(0.1).hex(),
  criticalMutedHover: critical.alpha(0.2).hex(),
  criticalHover: critical.darken(0.2).hex(),
  criticalMutedBorder: critical.alpha(0.5).hex(),
  textCritical: critical.hex(),
  textCriticalSecondary: critical.brighten(0.8).hex(),
} satisfies Record<string, string>;
