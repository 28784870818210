import { z, ZodSchema } from "zod";

const BaseErrorInfo = z.object({
  name: z.string(),
  message: z.string(),
  stack: z.string().optional(),
});
type BaseErrorInfo = z.infer<typeof BaseErrorInfo>;

export type ErrorInfo = BaseErrorInfo & { cause: ErrorInfo | null };
export const ErrorInfo: ZodSchema<ErrorInfo> = BaseErrorInfo.extend({
  cause: z.lazy(() => ErrorInfo.nullable()),
});
