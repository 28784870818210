import { z } from "zod";

import { ConsoleEvent } from "./console-event.js";
import { InteractionEvent } from "./interaction-event.js";
import { NetworkEvent } from "./network-event.js";

export const CapturedEvent = z.union([
  InteractionEvent,
  ConsoleEvent,
  NetworkEvent,
]);
export type CapturedEvent = z.infer<typeof CapturedEvent>;
