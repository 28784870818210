import { originalError } from "../utils/original-log.js";
// Elements will be ignored if their tag name is one of these
const IGNORE_NODES = ["script", "noscript", "capture-widget"];
// Elements will be ignored if they have an attribute/value pair matching one of these
const IGNORE_ATTRIBUTE_VALUES = [["rel", "preload"]];
// Attributes that should be absolute
const ABSOLUTE_ATTRIBUTES = ["src", "href", "srcset"];
const shouldIgnoreElement = (element) => {
    if (IGNORE_NODES.includes(element.tag)) {
        return true;
    }
    for (const [key, value] of IGNORE_ATTRIBUTE_VALUES) {
        if (element.attr[key]?.toLowerCase() === value) {
            return true;
        }
    }
    return false;
};
const toAbsoluteUrl = (path) => {
    const trimmed = path.trim();
    if (trimmed.startsWith("data:")) {
        return trimmed;
    }
    return new URL(trimmed, document.baseURI).toString();
};
const attributeToAbsoluteUrl = (attributeName, value) => {
    if (attributeName === "srcset") {
        const parts = value.split(/,(\s+)?/g).reduce((acc, part) => {
            if (!part) {
                return acc;
            }
            const [url, descriptor] = part.split(/\s+/g);
            if (descriptor?.trim()) {
                acc.push(`${toAbsoluteUrl(url)} ${descriptor}`);
            }
            else if (url.trim()) {
                acc.push(toAbsoluteUrl(url));
            }
            return acc;
        }, []);
        return parts.join(", ");
    }
    return new URL(value, document.baseURI).toString();
};
const serializeAttributes = (attributes) => {
    return Array.from(attributes).reduce((acc, attr) => {
        if (ABSOLUTE_ATTRIBUTES.includes(attr.name)) {
            acc[attr.name] = attributeToAbsoluteUrl(attr.name, attr.value);
        }
        else {
            acc[attr.name] = attr.value;
        }
        return acc;
    }, {});
};
const isElementNode = (node) => {
    return node.nodeType === node.ELEMENT_NODE;
};
const isTextNode = (node) => {
    return node.nodeType === node.TEXT_NODE;
};
const isStyleNode = (node) => {
    return (node.tagName === "STYLE" ||
        (node.tagName === "LINK" && node.getAttribute("rel") === "stylesheet"));
};
const serializeShadowRoot = (node, context) => {
    return {
        type: "shadow",
        adoptedStylesheets: getAdoptedStylesheets(node.adoptedStyleSheets, context),
        children: Array.from(node.childNodes)
            .map((node) => serializeNode(node, context))
            .filter((node) => node !== null),
    };
};
const getExtraAttributes = (node) => {
    if (node instanceof HTMLImageElement || node instanceof HTMLVideoElement) {
        return {
            width: node.getAttribute("width") ?? `${node.offsetWidth}px`,
            height: node.getAttribute("height") ?? `${node.offsetHeight}px`,
        };
    }
    return {};
};
const serializeElementNode = (node, context) => {
    const scrollLeft = node.scrollLeft;
    const scrollTop = node.scrollTop;
    const isSvg = node.tagName === "svg" || node instanceof SVGElement ? true : undefined;
    const tag = isSvg ? node.tagName : node.tagName.toLowerCase();
    const serialized = {
        type: "el",
        isSvg,
        tag,
        scroll: scrollLeft || scrollTop ? [scrollLeft, scrollTop] : undefined,
        attr: {
            ...serializeAttributes(node.attributes),
            ...getExtraAttributes(node),
        },
        shadow: node.shadowRoot
            ? serializeShadowRoot(node.shadowRoot, context)
            : undefined,
        children: Array.from(node.childNodes)
            .map((node) => serializeNode(node, context))
            .filter((node) => node !== null),
    };
    if (shouldIgnoreElement(serialized)) {
        return null;
    }
    return serialized;
};
const serializeTextNode = (node) => {
    return {
        type: "text",
        content: node.textContent,
    };
};
const serializeStyleNode = (node, context) => {
    const sheet = node.sheet;
    if (!sheet) {
        return serializeElementNode(node, context);
    }
    try {
        // This will throw if the stylesheet is cross-origin in which case
        // we will serialize it as a normal html node
        sheet.cssRules[0];
    }
    catch (error) {
        return serializeElementNode(node, context);
    }
    let id = context.stylesheets.get(sheet);
    if (id === undefined) {
        id = context.stylesheets.size;
        context.stylesheets.set(sheet, id);
    }
    return {
        type: "style",
        sheetId: id,
    };
};
const serializeNode = (node, context) => {
    if (isElementNode(node)) {
        if (isStyleNode(node)) {
            return serializeStyleNode(node, context);
        }
        return serializeElementNode(node, context);
    }
    else if (isTextNode(node)) {
        return serializeTextNode(node);
    }
    else {
        return null;
    }
};
const getAdoptedStylesheets = (adoptedStylesheets, context) => {
    return adoptedStylesheets.map((stylesheet) => {
        let id = context.stylesheets.get(stylesheet);
        if (id === undefined) {
            id = context.stylesheets.size;
            context.stylesheets.set(stylesheet, id);
        }
        return id;
    });
};
const getRulesForSheet = (stylesheet) => {
    let output = [];
    for (const rule of stylesheet.cssRules) {
        // If it's a media rule, check if it applies to the current document
        if (rule instanceof CSSMediaRule) {
            if (window.matchMedia(rule.media.mediaText).matches) {
                // If yes, the rules for the media rule should be included
                const parsedRules = getRulesForSheet(rule);
                output = [...output, ...parsedRules];
            }
        }
        else {
            output.push(rule.cssText);
        }
    }
    return output;
};
const serializeStylesheets = (stylesheets) => {
    const output = [];
    stylesheets.forEach((id, stylesheet) => {
        try {
            output.push({
                id,
                href: stylesheet.href ?? undefined,
                rules: getRulesForSheet(stylesheet),
            });
        }
        catch (error) {
            // eslint-disable-next-line no-console
            originalError("Could not serialize stylesheet", stylesheet);
        }
    });
    return output;
};
export const createSnapshot = () => {
    const context = {
        stylesheets: new Map(),
    };
    const html = document.querySelector("html");
    if (html === null) {
        throw new Error("Could not find root node");
    }
    const root = serializeElementNode(html, context);
    if (root === null) {
        throw new Error("Could not serialize root node");
    }
    const adoptedStylesheets = getAdoptedStylesheets(document.adoptedStyleSheets, context);
    const stylesheets = serializeStylesheets(context.stylesheets);
    return {
        type: "doc",
        root,
        adoptedStylesheets,
        stylesheets,
    };
};
