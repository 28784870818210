import { css } from "@emotion/css";
import {
  color,
  radii,
  space,
  fontSize,
  fontWeight,
  controlHeight,
  fontFamily,
  lineHeight,
} from "@styles";

export const badge = css`
  font-weight: ${fontWeight.regular};
  background: var(--badge-background-color);
  color: var(--badge-color);
  border-radius: ${radii.sm};
  font-size: var(--badge-font-size);
  line-height: var(--badge-line-height);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: var(--badge-size);
  min-width: var(--badge-size);
  padding: calc((var(--badge-size) - var(--badge-line-height)) / 2)
    var(--badge-padding-x);
  gap: ${space(1)};
  svg {
    width: var(--badge-icon-size);
    height: var(--badge-icon-size);
  }
`;

export const hasBorder = css`
  border: solid 1px var(--badge-border-color);
`;

export const isMono = css`
  font-size: 13px;
  font-family: ${fontFamily.monospace};
`;

export const xs = css`
  --badge-size: ${controlHeight.xs};
  --badge-icon-size: 13px;
  --badge-font-size: ${fontSize.level2};
  --badge-line-height: ${lineHeight.level2};
  --badge-padding-x: ${space(1)};
`;

export const sm = css`
  --badge-size: ${controlHeight.sm};
  --badge-font-size: ${fontSize.level2};
  --badge-line-height: ${lineHeight.level2};
  --badge-padding-x: ${space(2)};
  --badge-icon-size: 16px;
`;

export const md = css`
  --badge-size: ${controlHeight.md};
  --badge-font-size: ${fontSize.level3};
  --badge-line-height: ${lineHeight.level3};
  --badge-padding-x: ${space(2)};
  --badge-icon-size: 18px;
`;

export const lg = css`
  --badge-size: ${controlHeight.lg};
  --badge-font-size: ${fontSize.level3};
  --badge-line-height: ${lineHeight.level3};
  --badge-padding-x: ${space(2)};
  --badge-icon-size: 24px;
`;

export const xl = css`
  --badge-size: ${controlHeight.xl};
  --badge-icon-size: 28px;
`;

export const round = css`
  border-radius: 100px;
`;

export const square = css`
  width: var(--badge-size);
`;

export const fillContainer = css`
  width: 100%;
`;

export const iconOnly = css`
  width: var(--badge-size);
  padding: 0;
`;

export const muted = css`
  --badge-background-color: ${color("muted")};
  --badge-color: ${color("textSecondary")};
`;

export const contrast = css`
  --badge-background-color: ${color("contrast")};
  --badge-color: ${color("textInverse")};
`;

export const success = css`
  --badge-background-color: ${color("success")};
  --badge-color: ${color("textOnSuccess")};
`;

export const danger = css`
  --badge-border-color: ${color("criticalMutedBorder")};
  --badge-background-color: ${color("criticalMuted")};
  --badge-color: ${color("textCritical")};
`;

export const subtle = css`
  --badge-background-color: transparent;
`;
