import { useContext } from "react";

export const createNonNullableContextHook = <T>(
  context: React.Context<T | null>,
  providerName: string
) => {
  return () => {
    const contextValue = useContext(context);
    if (contextValue === null) {
      throw new Error(
        `Can't read context. Must be used within a ${providerName}`
      );
    }
    return contextValue;
  };
};
