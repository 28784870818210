import { truncateInputValue } from "../../utils/truncate-value.js";
import { uniqueId } from "../../utils/unique-id.js";
import { EventRecorder } from "../event-recorder.js";
import { getElementInfo } from "./get-recorded-element.js";
const typeableElements = ["input", "textarea", "[contenteditable=true]"];
const getActiveElement = (doc = document) => {
    const active = doc.activeElement;
    if (active?.shadowRoot) {
        return getActiveElement(active.shadowRoot);
    }
    return null;
};
const shouldIgnoreEvent = (event) => {
    return (event.target instanceof Element &&
        event.target.tagName.toLowerCase() === "capture-widget");
};
const isTypeableElement = (target) => {
    return (target instanceof Element &&
        typeableElements.some((selector) => target.matches(selector)));
};
const isInteractiveElement = (target) => {
    return (target instanceof HTMLElement &&
        target.matches("button, a, input, textarea, select, option, [contenteditable=true], [tabindex], [role=button], [role=link], [role=checkbox], [role=radio], [role=menuitem], [role=menuitemcheckbox], [role=menuitemradio]"));
};
const getElementValue = (element) => {
    if ("value" in element && typeof element.value === "string") {
        return truncateInputValue(element.value);
    }
    else if (element instanceof HTMLElement) {
        return truncateInputValue(element.innerText);
    }
    return "";
};
export class InteractionEventRecorder extends EventRecorder {
    lastFocusElement = null;
    resizeTimeout = null;
    interval = null;
    previousHref = "";
    inputMethod = "mouse";
    start() {
        window.addEventListener("pointerdown", this.onPointerDown, true);
        window.addEventListener("submit", this.onSubmit, true);
        window.addEventListener("keydown", this.onKeyDown, true);
        window.addEventListener("focus", this.onFocus, true);
        window.addEventListener("resize", this.onResize, true);
        window.addEventListener("change", this.onChange, true);
        this.previousHref = window.location.href;
        this.interval = setInterval(this.onInterval, 50);
        const activeElement = getActiveElement();
        // Page may already have a focussed element
        if (activeElement && isTypeableElement(activeElement)) {
            this.onElementFocussed(activeElement);
        }
    }
    stop() {
        window.removeEventListener("pointerdown", this.onPointerDown, true);
        window.removeEventListener("keydown", this.onKeyDown, true);
        window.removeEventListener("focus", this.onFocus, true);
        window.removeEventListener("resize", this.onResize, true);
        window.removeEventListener("submit", this.onSubmit, true);
        window.removeEventListener("change", this.onChange, true);
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
    }
    onSubmit = (event) => {
        if (shouldIgnoreEvent(event)) {
            return;
        }
        const target = event.composedPath()[0];
        if (target instanceof HTMLFormElement) {
            this.recordEvent({
                id: uniqueId(),
                time: Date.now(),
                type: "submit",
                element: getElementInfo(target),
                extra: { target },
            });
        }
    };
    onPointerDown = (event) => {
        if (shouldIgnoreEvent(event)) {
            return;
        }
        this.inputMethod = "mouse";
        const target = event.composedPath()[0];
        if (isInteractiveElement(target)) {
            this.recordEvent({
                id: uniqueId(),
                time: Date.now(),
                type: "click",
                pointer: event.pointerType,
                element: getElementInfo(target),
                extra: { target },
            });
        }
    };
    onFocus = (event) => {
        if (shouldIgnoreEvent(event)) {
            return;
        }
        const target = event.composedPath()[0];
        if (target instanceof Element && this.inputMethod === "keyboard") {
            this.onElementFocussed(target);
        }
    };
    onKeyDown = (event) => {
        if (shouldIgnoreEvent(event)) {
            return;
        }
        if (event.key === "Tab") {
            this.inputMethod = "keyboard";
        }
        const target = event.composedPath()[0];
        if (event.key === "Tab" && target === this.lastFocusElement) {
            this.lastFocusElement = null;
            // Treat this as a focus event rather than a keypress
            return;
        }
        if (!isTypeableElement(target) &&
            !["Meta", "Shift", "Command", "Alt"].includes(event.key) // Ignore modifier keys if they are the only key pressed
        ) {
            this.recordEvent({
                id: uniqueId(),
                time: Date.now(),
                type: "keypress",
                key: event.key,
                meta: event.metaKey,
                ctrl: event.ctrlKey,
                shift: event.shiftKey,
                alt: event.altKey,
            });
        }
    };
    onResize = () => {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(() => {
            this.onWindowResized();
            this.resizeTimeout = null;
        }, 500);
    };
    onChange = (event) => {
        if (shouldIgnoreEvent(event)) {
            return;
        }
        const target = event.composedPath()[0];
        if (target instanceof Element) {
            const value = getElementValue(target);
            this.recordEvent({
                id: uniqueId(),
                time: Date.now(),
                type: "change",
                value,
                element: getElementInfo(target),
                extra: { target },
            });
        }
    };
    onInterval = () => {
        if (window.location.href !== this.previousHref) {
            this.recordEvent({
                id: uniqueId(),
                time: Date.now(),
                type: "navigation",
                url: window.location.href,
            });
            this.previousHref = window.location.href;
        }
    };
    onWindowResized = () => {
        this.recordEvent({
            id: uniqueId(),
            time: Date.now(),
            type: "resize",
            width: window.innerWidth,
            height: window.innerHeight,
        });
    };
    onElementFocussed = (element) => {
        this.recordEvent({
            id: uniqueId(),
            time: Date.now(),
            type: "focus",
            element: getElementInfo(element),
            extra: { target: element },
        });
    };
}
