import { uniqueId } from "../../utils/unique-id.js";
import { EventRecorder } from "../event-recorder.js";
import { patchFetch, patchRequest } from "./patch-fetch.js";
import { patchXhr } from "./patch-xhr.js";
export class NetworkEventRecorder extends EventRecorder {
    observer = null;
    unsetters = [];
    start() {
        performance.getEntriesByType("resource").forEach(this.handleResourceTiming);
        this.unsetters = [];
        this.unsetters.push(patchRequest());
        this.unsetters.push(patchFetch(this));
        this.unsetters.push(patchXhr(this));
        this.observer = new PerformanceObserver((list) => {
            list.getEntries().forEach((entry) => {
                if (entry instanceof PerformanceResourceTiming) {
                    if (entry.initiatorType !== "fetch" &&
                        entry.initiatorType !== "xmlhttprequest") {
                        this.handleResourceTiming(entry);
                    }
                }
            });
        });
        this.observer.observe({ entryTypes: ["resource"] });
    }
    stop() {
        this.unsetters.forEach((unsetter) => unsetter());
        this.observer?.disconnect();
    }
    handleResourceTiming = (entry) => {
        if (entry instanceof PerformanceResourceTiming) {
            this.recordEvent({
                id: uniqueId(),
                type: "resource",
                time: Math.round(performance.timeOrigin + entry.startTime),
                url: entry.name,
                initiator: entry.initiatorType,
                startTime: entry.startTime,
                endTime: entry.responseEnd,
                transferSize: entry.transferSize,
                encodedBodySize: entry.encodedBodySize,
                decodedBodySize: entry.decodedBodySize,
            });
        }
    };
}
