import { css } from "@emotion/css";
import { space } from "@styles";

export const container = css`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 ${space(4)};
`;

export const fullHeight = css`
  height: 100%;
`;

export const verticalCenter = css`
  justify-content: center;
`;

export const inner = css`
  width: 100%;
  margin: auto;
`;

export const sm = css`
  max-width: 880px;
`;

export const md = css`
  max-width: 1100px;
`;

export const lg = css`
  max-width: 1400px;
`;

export const padded = css`
  padding: 60px 0;
`;
