import { Container } from "@components/container/container.js";
import { MessageCard } from "@components/message-card/message-card.js";
import { IconType } from "@utils/types.js";
import { WrenchIcon } from "lucide-react";
import React from "react";


interface GlobalErrorProps {
  Icon?: IconType;
  title?: string;
  description?: string;
  cta?: React.ReactNode;
}

export const GlobalError = ({
  Icon = WrenchIcon,
  title = "Oops",
  description = "An unexpected error occurred",
  cta,
}: GlobalErrorProps) => {
  return (
    <Container fullHeight verticallyCentered>
      <MessageCard
        Icon={Icon}
        title={title}
        description={description}
        action={cta}
      />
    </Container>
  );
};
