import { TextSkeleton } from "../skeleton/text.skeleton.js";

import * as styles from "./page-header.styles";

export interface PageHeadingProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  actions?: React.ReactNode;
}

export const PageHeaderSkeleton = ({
  actions,
  title,
  ...rest
}: PageHeadingProps) => {
  return (
    <div {...rest} className={styles.root}>
      <div className={styles.content}>
        <h1>
          <TextSkeleton>{title}</TextSkeleton>
        </h1>
      </div>
      {actions ? <div className={styles.actions}>{actions}</div> : null}
    </div>
  );
};
