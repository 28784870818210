const allowedTypePrefixes = [
    "application/json",
    "application/xml",
    "application/x-www-form-urlencoded",
    "text/",
];
export const isTextResponse = (contentTypeHeader) => {
    const header = contentTypeHeader?.toLowerCase();
    return (header && allowedTypePrefixes.some((prefix) => header.startsWith(prefix)));
};
