import { css } from "@emotion/css";
import { color, radii, space, typography } from "@styles";

export const root = css`
  padding: ${space(3)} ${space(8)};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: ${radii.md};
  hgroup {
    max-width: 600px;
    h2 {
      margin-bottom: ${space(3)};
    }
    p {
      font: ${typography.body4};
    }
  }
`;

export const icon = css`
  margin-bottom: ${space(4)};
  color: ${color("textSecondary")};
`;

export const action = css`
  margin-top: ${space(4)};
`;
