import { connectToChild } from "penpal";
import { createSnapshot } from "../snapshot/create-snapshot.js";
import { originalError } from "../utils/original-log.js";
import { version } from "../version.js";
import { maxZIndex } from "./constants.js";
import { createLoader } from "./loading-spinner.js";
import { getPageDescription } from "./utils/get-page-description.js";
const WIDGET_OFFSET = 16;
const clamp = (value, min, max) => Math.min(Math.max(value, min), max);
const initialWidth = 360;
const initialHeight = 208;
export class WidgetController {
    client;
    width = 0;
    height = 0;
    offsetX = 0;
    offsetY = 0;
    elements = null;
    isVisible = false;
    connection = null;
    isTransitionedOut = false;
    constructor(client) {
        this.client = client;
    }
    setOffset(x, y) {
        const [posY, posX] = this.client.widgetPosition.split("-");
        const minX = posX === "left"
            ? WIDGET_OFFSET
            : this.width - window.innerWidth + WIDGET_OFFSET;
        const maxX = posX === "left"
            ? window.innerWidth - this.width - WIDGET_OFFSET
            : WIDGET_OFFSET;
        const minY = posY === "bottom"
            ? this.height - window.innerHeight + WIDGET_OFFSET
            : -WIDGET_OFFSET;
        const maxY = posY === "bottom"
            ? WIDGET_OFFSET
            : window.innerHeight - this.height - WIDGET_OFFSET;
        this.offsetX = clamp(x, minX, maxX);
        this.offsetY = clamp(y, minY, maxY);
        if (this.elements) {
            this.elements.wrapper.style.transform = `translate(${this.offsetX}px, ${this.offsetY}px)`;
        }
    }
    onWindowResize = () => {
        this.setOffset(this.offsetX, this.offsetY);
    };
    setSize(width, height) {
        this.width = width;
        this.height = height;
        if (this.elements) {
            const [y, x] = this.client.widgetPosition.split("-");
            if (y === "top") {
                this.elements.wrapper.style.top = `${WIDGET_OFFSET}px`;
            }
            else if (y === "bottom") {
                this.elements.wrapper.style.bottom = `${WIDGET_OFFSET}px`;
            }
            if (x === "left") {
                this.elements.wrapper.style.left = `${WIDGET_OFFSET}px`;
            }
            else if (x === "right") {
                this.elements.wrapper.style.right = `${WIDGET_OFFSET}px`;
            }
            this.elements.iframe.style.width = `${width}px`;
            this.elements.wrapper.style.width = `${width}px`;
            this.elements.iframe.style.height = `${height}px`;
            this.elements.wrapper.style.height = `${height}px`;
            this.elements.wrapper.style.borderRadius = "6px";
            this.elements.wrapper.style.border = "solid 1px rgb(234, 234, 234";
            this.elements.wrapper.style.boxShadow =
                "rgba(0, 0, 0, 0.1) 0px 0 90px 30px";
            this.elements.wrapper.style.maxWidth = `calc(100% - ${WIDGET_OFFSET * 2}px)`;
        }
    }
    goFullScreen = () => {
        this.offsetX = 0;
        this.offsetY = 0;
        if (this.elements) {
            this.elements.wrapper.style.removeProperty("border");
            this.elements.wrapper.style.removeProperty("box-shadow");
            this.elements.wrapper.style.removeProperty("border-radius");
            this.elements.wrapper.style.removeProperty("right");
            this.elements.wrapper.style.removeProperty("bottom");
            this.elements.wrapper.style.left = "0px";
            this.elements.wrapper.style.top = "0px";
            this.elements.iframe.style.width = `100%`;
            this.elements.iframe.style.height = `100%`;
            this.elements.wrapper.style.width = `100%`;
            this.elements.wrapper.style.height = `100%`;
            this.elements.wrapper.style.maxWidth = "100%";
        }
    };
    open = async () => {
        if (this.isVisible) {
            return;
        }
        let startOffsetX = 0;
        let startOffsetY = 0;
        this.isVisible = true;
        let host = document.querySelector("capture-widget");
        window.addEventListener("resize", this.onWindowResize);
        if (!host) {
            host = document.createElement("capture-widget");
            document.body.appendChild(host);
        }
        const shadow = host.shadowRoot || host.attachShadow({ mode: "open" });
        const wrapper = document.createElement("div");
        wrapper.style.position = "fixed";
        wrapper.style.zIndex = maxZIndex.toString();
        wrapper.style.overflow = "hidden";
        wrapper.style.opacity = "0";
        wrapper.style.transform = `scale(0.9)`;
        const spinner = createLoader();
        wrapper.appendChild(spinner);
        let url = this.client.widgetBaseUrl;
        if (this.client.captureKey) {
            url += `/${this.client.captureKey}`;
        }
        if (this.client.isExtension) {
            url += `?extension=true`;
        }
        const iframe = document.createElement("iframe");
        iframe.style.colorScheme = "light dark";
        iframe.style.maxWidth = "100%";
        iframe.style.position = "absolute";
        iframe.style.top = "0px";
        iframe.style.left = "0px";
        iframe.style.border = "none";
        iframe.setAttribute("allowTransparency", "true");
        iframe.src = url;
        iframe.style.opacity = "0";
        iframe.style.transition = "opacity 0.15s ease-out";
        iframe.setAttribute("allow", "camera;microphone;display-capture;clipboard-write");
        shadow.appendChild(wrapper);
        wrapper.appendChild(iframe);
        this.elements = { host, wrapper, iframe };
        // Enter animation
        this.setSize(initialWidth, initialHeight);
        wrapper.style.transition =
            "all 0.3s cubic-bezier(0.165, 1.650, 0.505, 1.650)";
        wrapper.offsetHeight;
        const handler = () => {
            wrapper.style.removeProperty("transition");
            wrapper.removeEventListener("transitionend", handler);
        };
        wrapper.addEventListener("transitionend", handler);
        wrapper.style.opacity = "1";
        wrapper.style.transform = `scale(1)`;
        try {
            const methods = {
                transitionOut: (opts) => {
                    return this.transitionOut(opts);
                },
                transitionIn: (opts) => {
                    return this.transitionIn(opts);
                },
                setSize: (width, height) => {
                    if (this.elements) {
                        this.setSize(width, height);
                    }
                },
                close: () => {
                    this.close();
                },
                onReportUploaded(reportUrl) {
                    // Notifies the parent window that the report has been uploaded
                    // Currently used during onboarding to detect when the user has created a report
                    window.postMessage({ type: "capture.report-uploaded", reportUrl }, window.location.origin);
                },
                onDragStart: () => {
                    startOffsetX = this.offsetX;
                    startOffsetY = this.offsetY;
                },
                onDragMove: (deltaX, deltaY) => {
                    this.setOffset(startOffsetX + deltaX, startOffsetY + deltaY);
                },
                onDragEnd: () => { },
                resetPosition: () => {
                    this.setOffset(0, 0);
                },
                startCapturingEvents: () => {
                    this.client.recorder.startRecording();
                },
                stopCapturingEvents: () => {
                    this.client.recorder.stopRecording();
                    return this.client.recorder.getCapturedEvents();
                },
                setBodyStyle: (properties) => {
                    const originalValues = {};
                    Object.keys(properties).forEach((key) => {
                        originalValues[key] = document.body.style.getPropertyValue(key);
                        document.body.style.setProperty(key, properties[key]);
                    });
                    return originalValues;
                },
                captureScreenshot: async () => {
                    return await this.client.captureScreenshot();
                },
                getClientInfo: () => ({
                    captureKey: this.client.captureKey,
                    sdkVersion: version,
                    windowWidth: window.innerWidth,
                    windowHeight: window.innerHeight,
                    graphQLEndpoint: this.client.graphQLEndpoint,
                    pageTitle: document.title,
                    pageDescription: getPageDescription(),
                    customFields: this.client.customFields,
                    url: window.location.href,
                    internalErrors: this.client.recorder.getInternalErrors(),
                    isExtension: this.client.isExtension,
                }),
                getSnapshot: async () => {
                    try {
                        const node = createSnapshot();
                        const events = this.client.recorder.getCapturedEvents();
                        this.client.recorder.recordSnapshotTaken();
                        const image = await this.client.captureScreenshot();
                        return {
                            status: "success",
                            snapshot: {
                                node,
                                events,
                                viewportWidth: window.innerWidth,
                                viewportHeight: window.innerHeight,
                                image,
                            },
                        };
                    }
                    catch (error) {
                        // eslint-disable-next-line no-console
                        originalError(error);
                        return { status: "error" };
                    }
                },
            };
            this.connection = connectToChild({
                iframe,
                methods: methods,
                timeout: 10000,
            });
            await this.connection.promise;
            iframe.style.opacity = "1";
            setTimeout(() => {
                spinner.remove();
            }, 300);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            originalError(error);
            this.close();
        }
    };
    close = () => {
        this.isVisible = false;
        window.removeEventListener("resize", this.onWindowResize);
        const host = document.querySelector("capture-widget");
        if (host) {
            host.remove();
        }
        if (this.connection) {
            this.connection.destroy();
        }
    };
    transitionIn = (opts) => {
        this.isTransitionedOut = false;
        const wrapper = this.elements?.wrapper;
        if (wrapper) {
            let transition;
            if ("fullScreen" in opts) {
                transition = "opacity 0.15s ease-out";
                this.goFullScreen();
            }
            else {
                transition = "opacity 0.15s ease-out, transform 0.15s ease-out";
                this.setSize(opts.width, opts.height);
            }
            return new Promise((resolve) => {
                const handler = () => {
                    wrapper.style.removeProperty("transition");
                    wrapper.removeEventListener("transitionend", handler);
                    resolve();
                };
                wrapper.addEventListener("transitionend", handler);
                wrapper.style.transition = transition;
                wrapper.offsetHeight;
                wrapper.style.opacity = "1";
                wrapper.style.transform = `scale(1)`;
            });
        }
    };
    transitionOut = (opts) => {
        const shouldAnimate = opts.shouldAnimate ?? true;
        const wrapper = this.elements?.wrapper;
        if (this.isTransitionedOut) {
            return;
        }
        this.isTransitionedOut = true;
        if (wrapper) {
            return new Promise((resolve) => {
                const handler = () => {
                    wrapper.style.removeProperty("transition");
                    wrapper.removeEventListener("transitionend", handler);
                    resolve();
                };
                if (shouldAnimate) {
                    wrapper.addEventListener("transitionend", handler);
                    wrapper.style.transition =
                        "opacity 0.15s ease-out, transform 0.15s ease-out";
                    wrapper.offsetHeight; // Force repaint
                }
                wrapper.style.opacity = "0";
                if (!opts.fullScreen) {
                    wrapper.style.transform = `scale(0.9)`;
                }
                wrapper.offsetHeight; // Force repaint
                if (!shouldAnimate) {
                    handler();
                }
            });
        }
    };
}
