/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as TestImport } from './routes/test'
import { Route as SwitchTeamImport } from './routes/switch-team'
import { Route as AuthRouteImport } from './routes/_auth/route'
import { Route as AppRouteImport } from './routes/_app/route'
import { Route as IndexImport } from './routes/index'
import { Route as WidgetIndexImport } from './routes/widget/index'
import { Route as PayIndexImport } from './routes/pay/index'
import { Route as WidgetCaptureKeyImport } from './routes/widget/$captureKey'
import { Route as InvitationMembershipIdImport } from './routes/invitation/$membershipId'
import { Route as AuthSetupProfileImport } from './routes/_auth/setup-profile'
import { Route as AppTeamIdRouteImport } from './routes/_app/$teamId/route'
import { Route as AuthOnboardingIndexImport } from './routes/_auth/onboarding/index'
import { Route as AppTeamIdIndexImport } from './routes/_app/$teamId/index'
import { Route as AuthAuthLoginImport } from './routes/_auth/auth/login'
import { Route as AppTSplatImport } from './routes/_app/t/$'
import { Route as AppExtensionPostInstallImport } from './routes/_app/extension/post-install'
import { Route as AppRShareIdRouteImport } from './routes/_app/r/$shareId/route'
import { Route as AppRShareIdIndexImport } from './routes/_app/r/$shareId/index'
import { Route as AppTeamIdTeamIndexImport } from './routes/_app/$teamId/team/index'
import { Route as AppTeamIdSubscriptionIndexImport } from './routes/_app/$teamId/subscription/index'
import { Route as AppTeamIdSetupIndexImport } from './routes/_app/$teamId/setup/index'
import { Route as AppTeamIdIntegrationsIndexImport } from './routes/_app/$teamId/integrations/index'
import { Route as AppRShareIdTimelineImport } from './routes/_app/r/$shareId/timeline'
import { Route as AppRShareIdDetailsImport } from './routes/_app/r/$shareId/details'
import { Route as AppTeamIdSetupExtensionImport } from './routes/_app/$teamId/setup/extension'
import { Route as AppTeamIdSetupEmbedCodeImport } from './routes/_app/$teamId/setup/embed-code'
import { Route as AppTeamIdAccountSettingsImport } from './routes/_app/$teamId/account/settings'
import { Route as AppTeamIdReportsReportIdRouteImport } from './routes/_app/$teamId/reports/$reportId/route'
import { Route as AppRShareIdNetworkIndexImport } from './routes/_app/r/$shareId/network.index'
import { Route as AppTeamIdReportsReportIdIndexImport } from './routes/_app/$teamId/reports/$reportId/index'
import { Route as AppRShareIdNetworkEventIdImport } from './routes/_app/r/$shareId/network.$eventId'
import { Route as AppTeamIdTeamMembershipsCreateImport } from './routes/_app/$teamId/team/memberships/create'
import { Route as AppTeamIdTeamMembershipsMembershipIdImport } from './routes/_app/$teamId/team/memberships/$membershipId'
import { Route as AppTeamIdSubscriptionCheckoutTransactionIdImport } from './routes/_app/$teamId/subscription/checkout/$transactionId'
import { Route as AppTeamIdReportsReportIdTimelineImport } from './routes/_app/$teamId/reports/$reportId/timeline'
import { Route as AppTeamIdReportsReportIdDetailsImport } from './routes/_app/$teamId/reports/$reportId/details'
import { Route as AppTeamIdReportsReportIdNetworkIndexImport } from './routes/_app/$teamId/reports/$reportId/network.index'
import { Route as AppTeamIdReportsReportIdNetworkEventIdImport } from './routes/_app/$teamId/reports/$reportId/network.$eventId'
import { Route as AppTeamIdIntegrationsTrelloTrelloConnectionIdEditImport } from './routes/_app/$teamId/integrations/trello/$trelloConnectionId/edit'
import { Route as AppTeamIdIntegrationsSlackSlackConnectionIdEditImport } from './routes/_app/$teamId/integrations/slack/$slackConnectionId/edit'
import { Route as AppTeamIdIntegrationsLinearLinearConnectionIdEditImport } from './routes/_app/$teamId/integrations/linear/$linearConnectionId/edit'

// Create/Update Routes

const TestRoute = TestImport.update({
  id: '/test',
  path: '/test',
  getParentRoute: () => rootRoute,
} as any)

const SwitchTeamRoute = SwitchTeamImport.update({
  id: '/switch-team',
  path: '/switch-team',
  getParentRoute: () => rootRoute,
} as any)

const AuthRouteRoute = AuthRouteImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AppRouteRoute = AppRouteImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const WidgetIndexRoute = WidgetIndexImport.update({
  id: '/widget/',
  path: '/widget/',
  getParentRoute: () => rootRoute,
} as any)

const PayIndexRoute = PayIndexImport.update({
  id: '/pay/',
  path: '/pay/',
  getParentRoute: () => rootRoute,
} as any)

const WidgetCaptureKeyRoute = WidgetCaptureKeyImport.update({
  id: '/widget/$captureKey',
  path: '/widget/$captureKey',
  getParentRoute: () => rootRoute,
} as any)

const InvitationMembershipIdRoute = InvitationMembershipIdImport.update({
  id: '/invitation/$membershipId',
  path: '/invitation/$membershipId',
  getParentRoute: () => rootRoute,
} as any)

const AuthSetupProfileRoute = AuthSetupProfileImport.update({
  id: '/setup-profile',
  path: '/setup-profile',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AppTeamIdRouteRoute = AppTeamIdRouteImport.update({
  id: '/$teamId',
  path: '/$teamId',
  getParentRoute: () => AppRouteRoute,
} as any)

const AuthOnboardingIndexRoute = AuthOnboardingIndexImport.update({
  id: '/onboarding/',
  path: '/onboarding/',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AppTeamIdIndexRoute = AppTeamIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AuthAuthLoginRoute = AuthAuthLoginImport.update({
  id: '/auth/login',
  path: '/auth/login',
  getParentRoute: () => AuthRouteRoute,
} as any)

const AppTSplatRoute = AppTSplatImport.update({
  id: '/t/$',
  path: '/t/$',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppExtensionPostInstallRoute = AppExtensionPostInstallImport.update({
  id: '/extension/post-install',
  path: '/extension/post-install',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppRShareIdRouteRoute = AppRShareIdRouteImport.update({
  id: '/r/$shareId',
  path: '/r/$shareId',
  getParentRoute: () => AppRouteRoute,
} as any)

const AppRShareIdIndexRoute = AppRShareIdIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppTeamIdTeamIndexRoute = AppTeamIdTeamIndexImport.update({
  id: '/team/',
  path: '/team/',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppTeamIdSubscriptionIndexRoute = AppTeamIdSubscriptionIndexImport.update(
  {
    id: '/subscription/',
    path: '/subscription/',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any,
)

const AppTeamIdSetupIndexRoute = AppTeamIdSetupIndexImport.update({
  id: '/setup/',
  path: '/setup/',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppTeamIdIntegrationsIndexRoute = AppTeamIdIntegrationsIndexImport.update(
  {
    id: '/integrations/',
    path: '/integrations/',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any,
)

const AppRShareIdTimelineRoute = AppRShareIdTimelineImport.update({
  id: '/timeline',
  path: '/timeline',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppRShareIdDetailsRoute = AppRShareIdDetailsImport.update({
  id: '/details',
  path: '/details',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppTeamIdSetupExtensionRoute = AppTeamIdSetupExtensionImport.update({
  id: '/setup/extension',
  path: '/setup/extension',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppTeamIdSetupEmbedCodeRoute = AppTeamIdSetupEmbedCodeImport.update({
  id: '/setup/embed-code',
  path: '/setup/embed-code',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppTeamIdAccountSettingsRoute = AppTeamIdAccountSettingsImport.update({
  id: '/account/settings',
  path: '/account/settings',
  getParentRoute: () => AppTeamIdRouteRoute,
} as any)

const AppTeamIdReportsReportIdRouteRoute =
  AppTeamIdReportsReportIdRouteImport.update({
    id: '/reports/$reportId',
    path: '/reports/$reportId',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any)

const AppRShareIdNetworkIndexRoute = AppRShareIdNetworkIndexImport.update({
  id: '/network/',
  path: '/network/',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppTeamIdReportsReportIdIndexRoute =
  AppTeamIdReportsReportIdIndexImport.update({
    id: '/',
    path: '/',
    getParentRoute: () => AppTeamIdReportsReportIdRouteRoute,
  } as any)

const AppRShareIdNetworkEventIdRoute = AppRShareIdNetworkEventIdImport.update({
  id: '/network/$eventId',
  path: '/network/$eventId',
  getParentRoute: () => AppRShareIdRouteRoute,
} as any)

const AppTeamIdTeamMembershipsCreateRoute =
  AppTeamIdTeamMembershipsCreateImport.update({
    id: '/team/memberships/create',
    path: '/team/memberships/create',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any)

const AppTeamIdTeamMembershipsMembershipIdRoute =
  AppTeamIdTeamMembershipsMembershipIdImport.update({
    id: '/team/memberships/$membershipId',
    path: '/team/memberships/$membershipId',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any)

const AppTeamIdSubscriptionCheckoutTransactionIdRoute =
  AppTeamIdSubscriptionCheckoutTransactionIdImport.update({
    id: '/subscription/checkout/$transactionId',
    path: '/subscription/checkout/$transactionId',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any)

const AppTeamIdReportsReportIdTimelineRoute =
  AppTeamIdReportsReportIdTimelineImport.update({
    id: '/timeline',
    path: '/timeline',
    getParentRoute: () => AppTeamIdReportsReportIdRouteRoute,
  } as any)

const AppTeamIdReportsReportIdDetailsRoute =
  AppTeamIdReportsReportIdDetailsImport.update({
    id: '/details',
    path: '/details',
    getParentRoute: () => AppTeamIdReportsReportIdRouteRoute,
  } as any)

const AppTeamIdReportsReportIdNetworkIndexRoute =
  AppTeamIdReportsReportIdNetworkIndexImport.update({
    id: '/network/',
    path: '/network/',
    getParentRoute: () => AppTeamIdReportsReportIdRouteRoute,
  } as any)

const AppTeamIdReportsReportIdNetworkEventIdRoute =
  AppTeamIdReportsReportIdNetworkEventIdImport.update({
    id: '/network/$eventId',
    path: '/network/$eventId',
    getParentRoute: () => AppTeamIdReportsReportIdRouteRoute,
  } as any)

const AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute =
  AppTeamIdIntegrationsTrelloTrelloConnectionIdEditImport.update({
    id: '/integrations/trello/$trelloConnectionId/edit',
    path: '/integrations/trello/$trelloConnectionId/edit',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any)

const AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute =
  AppTeamIdIntegrationsSlackSlackConnectionIdEditImport.update({
    id: '/integrations/slack/$slackConnectionId/edit',
    path: '/integrations/slack/$slackConnectionId/edit',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any)

const AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute =
  AppTeamIdIntegrationsLinearLinearConnectionIdEditImport.update({
    id: '/integrations/linear/$linearConnectionId/edit',
    path: '/integrations/linear/$linearConnectionId/edit',
    getParentRoute: () => AppTeamIdRouteRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppRouteImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthRouteImport
      parentRoute: typeof rootRoute
    }
    '/switch-team': {
      id: '/switch-team'
      path: '/switch-team'
      fullPath: '/switch-team'
      preLoaderRoute: typeof SwitchTeamImport
      parentRoute: typeof rootRoute
    }
    '/test': {
      id: '/test'
      path: '/test'
      fullPath: '/test'
      preLoaderRoute: typeof TestImport
      parentRoute: typeof rootRoute
    }
    '/_app/$teamId': {
      id: '/_app/$teamId'
      path: '/$teamId'
      fullPath: '/$teamId'
      preLoaderRoute: typeof AppTeamIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/_auth/setup-profile': {
      id: '/_auth/setup-profile'
      path: '/setup-profile'
      fullPath: '/setup-profile'
      preLoaderRoute: typeof AuthSetupProfileImport
      parentRoute: typeof AuthRouteImport
    }
    '/invitation/$membershipId': {
      id: '/invitation/$membershipId'
      path: '/invitation/$membershipId'
      fullPath: '/invitation/$membershipId'
      preLoaderRoute: typeof InvitationMembershipIdImport
      parentRoute: typeof rootRoute
    }
    '/widget/$captureKey': {
      id: '/widget/$captureKey'
      path: '/widget/$captureKey'
      fullPath: '/widget/$captureKey'
      preLoaderRoute: typeof WidgetCaptureKeyImport
      parentRoute: typeof rootRoute
    }
    '/pay/': {
      id: '/pay/'
      path: '/pay'
      fullPath: '/pay'
      preLoaderRoute: typeof PayIndexImport
      parentRoute: typeof rootRoute
    }
    '/widget/': {
      id: '/widget/'
      path: '/widget'
      fullPath: '/widget'
      preLoaderRoute: typeof WidgetIndexImport
      parentRoute: typeof rootRoute
    }
    '/_app/r/$shareId': {
      id: '/_app/r/$shareId'
      path: '/r/$shareId'
      fullPath: '/r/$shareId'
      preLoaderRoute: typeof AppRShareIdRouteImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/extension/post-install': {
      id: '/_app/extension/post-install'
      path: '/extension/post-install'
      fullPath: '/extension/post-install'
      preLoaderRoute: typeof AppExtensionPostInstallImport
      parentRoute: typeof AppRouteImport
    }
    '/_app/t/$': {
      id: '/_app/t/$'
      path: '/t/$'
      fullPath: '/t/$'
      preLoaderRoute: typeof AppTSplatImport
      parentRoute: typeof AppRouteImport
    }
    '/_auth/auth/login': {
      id: '/_auth/auth/login'
      path: '/auth/login'
      fullPath: '/auth/login'
      preLoaderRoute: typeof AuthAuthLoginImport
      parentRoute: typeof AuthRouteImport
    }
    '/_app/$teamId/': {
      id: '/_app/$teamId/'
      path: '/'
      fullPath: '/$teamId/'
      preLoaderRoute: typeof AppTeamIdIndexImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_auth/onboarding/': {
      id: '/_auth/onboarding/'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof AuthOnboardingIndexImport
      parentRoute: typeof AuthRouteImport
    }
    '/_app/$teamId/reports/$reportId': {
      id: '/_app/$teamId/reports/$reportId'
      path: '/reports/$reportId'
      fullPath: '/$teamId/reports/$reportId'
      preLoaderRoute: typeof AppTeamIdReportsReportIdRouteImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/account/settings': {
      id: '/_app/$teamId/account/settings'
      path: '/account/settings'
      fullPath: '/$teamId/account/settings'
      preLoaderRoute: typeof AppTeamIdAccountSettingsImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/setup/embed-code': {
      id: '/_app/$teamId/setup/embed-code'
      path: '/setup/embed-code'
      fullPath: '/$teamId/setup/embed-code'
      preLoaderRoute: typeof AppTeamIdSetupEmbedCodeImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/setup/extension': {
      id: '/_app/$teamId/setup/extension'
      path: '/setup/extension'
      fullPath: '/$teamId/setup/extension'
      preLoaderRoute: typeof AppTeamIdSetupExtensionImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/r/$shareId/details': {
      id: '/_app/r/$shareId/details'
      path: '/details'
      fullPath: '/r/$shareId/details'
      preLoaderRoute: typeof AppRShareIdDetailsImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/r/$shareId/timeline': {
      id: '/_app/r/$shareId/timeline'
      path: '/timeline'
      fullPath: '/r/$shareId/timeline'
      preLoaderRoute: typeof AppRShareIdTimelineImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/$teamId/integrations/': {
      id: '/_app/$teamId/integrations/'
      path: '/integrations'
      fullPath: '/$teamId/integrations'
      preLoaderRoute: typeof AppTeamIdIntegrationsIndexImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/setup/': {
      id: '/_app/$teamId/setup/'
      path: '/setup'
      fullPath: '/$teamId/setup'
      preLoaderRoute: typeof AppTeamIdSetupIndexImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/subscription/': {
      id: '/_app/$teamId/subscription/'
      path: '/subscription'
      fullPath: '/$teamId/subscription'
      preLoaderRoute: typeof AppTeamIdSubscriptionIndexImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/team/': {
      id: '/_app/$teamId/team/'
      path: '/team'
      fullPath: '/$teamId/team'
      preLoaderRoute: typeof AppTeamIdTeamIndexImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/r/$shareId/': {
      id: '/_app/r/$shareId/'
      path: '/'
      fullPath: '/r/$shareId/'
      preLoaderRoute: typeof AppRShareIdIndexImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/$teamId/reports/$reportId/details': {
      id: '/_app/$teamId/reports/$reportId/details'
      path: '/details'
      fullPath: '/$teamId/reports/$reportId/details'
      preLoaderRoute: typeof AppTeamIdReportsReportIdDetailsImport
      parentRoute: typeof AppTeamIdReportsReportIdRouteImport
    }
    '/_app/$teamId/reports/$reportId/timeline': {
      id: '/_app/$teamId/reports/$reportId/timeline'
      path: '/timeline'
      fullPath: '/$teamId/reports/$reportId/timeline'
      preLoaderRoute: typeof AppTeamIdReportsReportIdTimelineImport
      parentRoute: typeof AppTeamIdReportsReportIdRouteImport
    }
    '/_app/$teamId/subscription/checkout/$transactionId': {
      id: '/_app/$teamId/subscription/checkout/$transactionId'
      path: '/subscription/checkout/$transactionId'
      fullPath: '/$teamId/subscription/checkout/$transactionId'
      preLoaderRoute: typeof AppTeamIdSubscriptionCheckoutTransactionIdImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/team/memberships/$membershipId': {
      id: '/_app/$teamId/team/memberships/$membershipId'
      path: '/team/memberships/$membershipId'
      fullPath: '/$teamId/team/memberships/$membershipId'
      preLoaderRoute: typeof AppTeamIdTeamMembershipsMembershipIdImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/team/memberships/create': {
      id: '/_app/$teamId/team/memberships/create'
      path: '/team/memberships/create'
      fullPath: '/$teamId/team/memberships/create'
      preLoaderRoute: typeof AppTeamIdTeamMembershipsCreateImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/r/$shareId/network/$eventId': {
      id: '/_app/r/$shareId/network/$eventId'
      path: '/network/$eventId'
      fullPath: '/r/$shareId/network/$eventId'
      preLoaderRoute: typeof AppRShareIdNetworkEventIdImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/$teamId/reports/$reportId/': {
      id: '/_app/$teamId/reports/$reportId/'
      path: '/'
      fullPath: '/$teamId/reports/$reportId/'
      preLoaderRoute: typeof AppTeamIdReportsReportIdIndexImport
      parentRoute: typeof AppTeamIdReportsReportIdRouteImport
    }
    '/_app/r/$shareId/network/': {
      id: '/_app/r/$shareId/network/'
      path: '/network'
      fullPath: '/r/$shareId/network'
      preLoaderRoute: typeof AppRShareIdNetworkIndexImport
      parentRoute: typeof AppRShareIdRouteImport
    }
    '/_app/$teamId/integrations/linear/$linearConnectionId/edit': {
      id: '/_app/$teamId/integrations/linear/$linearConnectionId/edit'
      path: '/integrations/linear/$linearConnectionId/edit'
      fullPath: '/$teamId/integrations/linear/$linearConnectionId/edit'
      preLoaderRoute: typeof AppTeamIdIntegrationsLinearLinearConnectionIdEditImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/integrations/slack/$slackConnectionId/edit': {
      id: '/_app/$teamId/integrations/slack/$slackConnectionId/edit'
      path: '/integrations/slack/$slackConnectionId/edit'
      fullPath: '/$teamId/integrations/slack/$slackConnectionId/edit'
      preLoaderRoute: typeof AppTeamIdIntegrationsSlackSlackConnectionIdEditImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/integrations/trello/$trelloConnectionId/edit': {
      id: '/_app/$teamId/integrations/trello/$trelloConnectionId/edit'
      path: '/integrations/trello/$trelloConnectionId/edit'
      fullPath: '/$teamId/integrations/trello/$trelloConnectionId/edit'
      preLoaderRoute: typeof AppTeamIdIntegrationsTrelloTrelloConnectionIdEditImport
      parentRoute: typeof AppTeamIdRouteImport
    }
    '/_app/$teamId/reports/$reportId/network/$eventId': {
      id: '/_app/$teamId/reports/$reportId/network/$eventId'
      path: '/network/$eventId'
      fullPath: '/$teamId/reports/$reportId/network/$eventId'
      preLoaderRoute: typeof AppTeamIdReportsReportIdNetworkEventIdImport
      parentRoute: typeof AppTeamIdReportsReportIdRouteImport
    }
    '/_app/$teamId/reports/$reportId/network/': {
      id: '/_app/$teamId/reports/$reportId/network/'
      path: '/network'
      fullPath: '/$teamId/reports/$reportId/network'
      preLoaderRoute: typeof AppTeamIdReportsReportIdNetworkIndexImport
      parentRoute: typeof AppTeamIdReportsReportIdRouteImport
    }
  }
}

// Create and export the route tree

interface AppTeamIdReportsReportIdRouteRouteChildren {
  AppTeamIdReportsReportIdDetailsRoute: typeof AppTeamIdReportsReportIdDetailsRoute
  AppTeamIdReportsReportIdTimelineRoute: typeof AppTeamIdReportsReportIdTimelineRoute
  AppTeamIdReportsReportIdIndexRoute: typeof AppTeamIdReportsReportIdIndexRoute
  AppTeamIdReportsReportIdNetworkEventIdRoute: typeof AppTeamIdReportsReportIdNetworkEventIdRoute
  AppTeamIdReportsReportIdNetworkIndexRoute: typeof AppTeamIdReportsReportIdNetworkIndexRoute
}

const AppTeamIdReportsReportIdRouteRouteChildren: AppTeamIdReportsReportIdRouteRouteChildren =
  {
    AppTeamIdReportsReportIdDetailsRoute: AppTeamIdReportsReportIdDetailsRoute,
    AppTeamIdReportsReportIdTimelineRoute:
      AppTeamIdReportsReportIdTimelineRoute,
    AppTeamIdReportsReportIdIndexRoute: AppTeamIdReportsReportIdIndexRoute,
    AppTeamIdReportsReportIdNetworkEventIdRoute:
      AppTeamIdReportsReportIdNetworkEventIdRoute,
    AppTeamIdReportsReportIdNetworkIndexRoute:
      AppTeamIdReportsReportIdNetworkIndexRoute,
  }

const AppTeamIdReportsReportIdRouteRouteWithChildren =
  AppTeamIdReportsReportIdRouteRoute._addFileChildren(
    AppTeamIdReportsReportIdRouteRouteChildren,
  )

interface AppTeamIdRouteRouteChildren {
  AppTeamIdIndexRoute: typeof AppTeamIdIndexRoute
  AppTeamIdReportsReportIdRouteRoute: typeof AppTeamIdReportsReportIdRouteRouteWithChildren
  AppTeamIdAccountSettingsRoute: typeof AppTeamIdAccountSettingsRoute
  AppTeamIdSetupEmbedCodeRoute: typeof AppTeamIdSetupEmbedCodeRoute
  AppTeamIdSetupExtensionRoute: typeof AppTeamIdSetupExtensionRoute
  AppTeamIdIntegrationsIndexRoute: typeof AppTeamIdIntegrationsIndexRoute
  AppTeamIdSetupIndexRoute: typeof AppTeamIdSetupIndexRoute
  AppTeamIdSubscriptionIndexRoute: typeof AppTeamIdSubscriptionIndexRoute
  AppTeamIdTeamIndexRoute: typeof AppTeamIdTeamIndexRoute
  AppTeamIdSubscriptionCheckoutTransactionIdRoute: typeof AppTeamIdSubscriptionCheckoutTransactionIdRoute
  AppTeamIdTeamMembershipsMembershipIdRoute: typeof AppTeamIdTeamMembershipsMembershipIdRoute
  AppTeamIdTeamMembershipsCreateRoute: typeof AppTeamIdTeamMembershipsCreateRoute
  AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute: typeof AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute
  AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute: typeof AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute
  AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute: typeof AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute
}

const AppTeamIdRouteRouteChildren: AppTeamIdRouteRouteChildren = {
  AppTeamIdIndexRoute: AppTeamIdIndexRoute,
  AppTeamIdReportsReportIdRouteRoute:
    AppTeamIdReportsReportIdRouteRouteWithChildren,
  AppTeamIdAccountSettingsRoute: AppTeamIdAccountSettingsRoute,
  AppTeamIdSetupEmbedCodeRoute: AppTeamIdSetupEmbedCodeRoute,
  AppTeamIdSetupExtensionRoute: AppTeamIdSetupExtensionRoute,
  AppTeamIdIntegrationsIndexRoute: AppTeamIdIntegrationsIndexRoute,
  AppTeamIdSetupIndexRoute: AppTeamIdSetupIndexRoute,
  AppTeamIdSubscriptionIndexRoute: AppTeamIdSubscriptionIndexRoute,
  AppTeamIdTeamIndexRoute: AppTeamIdTeamIndexRoute,
  AppTeamIdSubscriptionCheckoutTransactionIdRoute:
    AppTeamIdSubscriptionCheckoutTransactionIdRoute,
  AppTeamIdTeamMembershipsMembershipIdRoute:
    AppTeamIdTeamMembershipsMembershipIdRoute,
  AppTeamIdTeamMembershipsCreateRoute: AppTeamIdTeamMembershipsCreateRoute,
  AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute:
    AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute,
  AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute:
    AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute,
  AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute:
    AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute,
}

const AppTeamIdRouteRouteWithChildren = AppTeamIdRouteRoute._addFileChildren(
  AppTeamIdRouteRouteChildren,
)

interface AppRShareIdRouteRouteChildren {
  AppRShareIdDetailsRoute: typeof AppRShareIdDetailsRoute
  AppRShareIdTimelineRoute: typeof AppRShareIdTimelineRoute
  AppRShareIdIndexRoute: typeof AppRShareIdIndexRoute
  AppRShareIdNetworkEventIdRoute: typeof AppRShareIdNetworkEventIdRoute
  AppRShareIdNetworkIndexRoute: typeof AppRShareIdNetworkIndexRoute
}

const AppRShareIdRouteRouteChildren: AppRShareIdRouteRouteChildren = {
  AppRShareIdDetailsRoute: AppRShareIdDetailsRoute,
  AppRShareIdTimelineRoute: AppRShareIdTimelineRoute,
  AppRShareIdIndexRoute: AppRShareIdIndexRoute,
  AppRShareIdNetworkEventIdRoute: AppRShareIdNetworkEventIdRoute,
  AppRShareIdNetworkIndexRoute: AppRShareIdNetworkIndexRoute,
}

const AppRShareIdRouteRouteWithChildren =
  AppRShareIdRouteRoute._addFileChildren(AppRShareIdRouteRouteChildren)

interface AppRouteRouteChildren {
  AppTeamIdRouteRoute: typeof AppTeamIdRouteRouteWithChildren
  AppRShareIdRouteRoute: typeof AppRShareIdRouteRouteWithChildren
  AppExtensionPostInstallRoute: typeof AppExtensionPostInstallRoute
  AppTSplatRoute: typeof AppTSplatRoute
}

const AppRouteRouteChildren: AppRouteRouteChildren = {
  AppTeamIdRouteRoute: AppTeamIdRouteRouteWithChildren,
  AppRShareIdRouteRoute: AppRShareIdRouteRouteWithChildren,
  AppExtensionPostInstallRoute: AppExtensionPostInstallRoute,
  AppTSplatRoute: AppTSplatRoute,
}

const AppRouteRouteWithChildren = AppRouteRoute._addFileChildren(
  AppRouteRouteChildren,
)

interface AuthRouteRouteChildren {
  AuthSetupProfileRoute: typeof AuthSetupProfileRoute
  AuthAuthLoginRoute: typeof AuthAuthLoginRoute
  AuthOnboardingIndexRoute: typeof AuthOnboardingIndexRoute
}

const AuthRouteRouteChildren: AuthRouteRouteChildren = {
  AuthSetupProfileRoute: AuthSetupProfileRoute,
  AuthAuthLoginRoute: AuthAuthLoginRoute,
  AuthOnboardingIndexRoute: AuthOnboardingIndexRoute,
}

const AuthRouteRouteWithChildren = AuthRouteRoute._addFileChildren(
  AuthRouteRouteChildren,
)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteRouteWithChildren
  '/switch-team': typeof SwitchTeamRoute
  '/test': typeof TestRoute
  '/$teamId': typeof AppTeamIdRouteRouteWithChildren
  '/setup-profile': typeof AuthSetupProfileRoute
  '/invitation/$membershipId': typeof InvitationMembershipIdRoute
  '/widget/$captureKey': typeof WidgetCaptureKeyRoute
  '/pay': typeof PayIndexRoute
  '/widget': typeof WidgetIndexRoute
  '/r/$shareId': typeof AppRShareIdRouteRouteWithChildren
  '/extension/post-install': typeof AppExtensionPostInstallRoute
  '/t/$': typeof AppTSplatRoute
  '/auth/login': typeof AuthAuthLoginRoute
  '/$teamId/': typeof AppTeamIdIndexRoute
  '/onboarding': typeof AuthOnboardingIndexRoute
  '/$teamId/reports/$reportId': typeof AppTeamIdReportsReportIdRouteRouteWithChildren
  '/$teamId/account/settings': typeof AppTeamIdAccountSettingsRoute
  '/$teamId/setup/embed-code': typeof AppTeamIdSetupEmbedCodeRoute
  '/$teamId/setup/extension': typeof AppTeamIdSetupExtensionRoute
  '/r/$shareId/details': typeof AppRShareIdDetailsRoute
  '/r/$shareId/timeline': typeof AppRShareIdTimelineRoute
  '/$teamId/integrations': typeof AppTeamIdIntegrationsIndexRoute
  '/$teamId/setup': typeof AppTeamIdSetupIndexRoute
  '/$teamId/subscription': typeof AppTeamIdSubscriptionIndexRoute
  '/$teamId/team': typeof AppTeamIdTeamIndexRoute
  '/r/$shareId/': typeof AppRShareIdIndexRoute
  '/$teamId/reports/$reportId/details': typeof AppTeamIdReportsReportIdDetailsRoute
  '/$teamId/reports/$reportId/timeline': typeof AppTeamIdReportsReportIdTimelineRoute
  '/$teamId/subscription/checkout/$transactionId': typeof AppTeamIdSubscriptionCheckoutTransactionIdRoute
  '/$teamId/team/memberships/$membershipId': typeof AppTeamIdTeamMembershipsMembershipIdRoute
  '/$teamId/team/memberships/create': typeof AppTeamIdTeamMembershipsCreateRoute
  '/r/$shareId/network/$eventId': typeof AppRShareIdNetworkEventIdRoute
  '/$teamId/reports/$reportId/': typeof AppTeamIdReportsReportIdIndexRoute
  '/r/$shareId/network': typeof AppRShareIdNetworkIndexRoute
  '/$teamId/integrations/linear/$linearConnectionId/edit': typeof AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute
  '/$teamId/integrations/slack/$slackConnectionId/edit': typeof AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute
  '/$teamId/integrations/trello/$trelloConnectionId/edit': typeof AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute
  '/$teamId/reports/$reportId/network/$eventId': typeof AppTeamIdReportsReportIdNetworkEventIdRoute
  '/$teamId/reports/$reportId/network': typeof AppTeamIdReportsReportIdNetworkIndexRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteRouteWithChildren
  '/switch-team': typeof SwitchTeamRoute
  '/test': typeof TestRoute
  '/setup-profile': typeof AuthSetupProfileRoute
  '/invitation/$membershipId': typeof InvitationMembershipIdRoute
  '/widget/$captureKey': typeof WidgetCaptureKeyRoute
  '/pay': typeof PayIndexRoute
  '/widget': typeof WidgetIndexRoute
  '/extension/post-install': typeof AppExtensionPostInstallRoute
  '/t/$': typeof AppTSplatRoute
  '/auth/login': typeof AuthAuthLoginRoute
  '/$teamId': typeof AppTeamIdIndexRoute
  '/onboarding': typeof AuthOnboardingIndexRoute
  '/$teamId/account/settings': typeof AppTeamIdAccountSettingsRoute
  '/$teamId/setup/embed-code': typeof AppTeamIdSetupEmbedCodeRoute
  '/$teamId/setup/extension': typeof AppTeamIdSetupExtensionRoute
  '/r/$shareId/details': typeof AppRShareIdDetailsRoute
  '/r/$shareId/timeline': typeof AppRShareIdTimelineRoute
  '/$teamId/integrations': typeof AppTeamIdIntegrationsIndexRoute
  '/$teamId/setup': typeof AppTeamIdSetupIndexRoute
  '/$teamId/subscription': typeof AppTeamIdSubscriptionIndexRoute
  '/$teamId/team': typeof AppTeamIdTeamIndexRoute
  '/r/$shareId': typeof AppRShareIdIndexRoute
  '/$teamId/reports/$reportId/details': typeof AppTeamIdReportsReportIdDetailsRoute
  '/$teamId/reports/$reportId/timeline': typeof AppTeamIdReportsReportIdTimelineRoute
  '/$teamId/subscription/checkout/$transactionId': typeof AppTeamIdSubscriptionCheckoutTransactionIdRoute
  '/$teamId/team/memberships/$membershipId': typeof AppTeamIdTeamMembershipsMembershipIdRoute
  '/$teamId/team/memberships/create': typeof AppTeamIdTeamMembershipsCreateRoute
  '/r/$shareId/network/$eventId': typeof AppRShareIdNetworkEventIdRoute
  '/$teamId/reports/$reportId': typeof AppTeamIdReportsReportIdIndexRoute
  '/r/$shareId/network': typeof AppRShareIdNetworkIndexRoute
  '/$teamId/integrations/linear/$linearConnectionId/edit': typeof AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute
  '/$teamId/integrations/slack/$slackConnectionId/edit': typeof AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute
  '/$teamId/integrations/trello/$trelloConnectionId/edit': typeof AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute
  '/$teamId/reports/$reportId/network/$eventId': typeof AppTeamIdReportsReportIdNetworkEventIdRoute
  '/$teamId/reports/$reportId/network': typeof AppTeamIdReportsReportIdNetworkIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_app': typeof AppRouteRouteWithChildren
  '/_auth': typeof AuthRouteRouteWithChildren
  '/switch-team': typeof SwitchTeamRoute
  '/test': typeof TestRoute
  '/_app/$teamId': typeof AppTeamIdRouteRouteWithChildren
  '/_auth/setup-profile': typeof AuthSetupProfileRoute
  '/invitation/$membershipId': typeof InvitationMembershipIdRoute
  '/widget/$captureKey': typeof WidgetCaptureKeyRoute
  '/pay/': typeof PayIndexRoute
  '/widget/': typeof WidgetIndexRoute
  '/_app/r/$shareId': typeof AppRShareIdRouteRouteWithChildren
  '/_app/extension/post-install': typeof AppExtensionPostInstallRoute
  '/_app/t/$': typeof AppTSplatRoute
  '/_auth/auth/login': typeof AuthAuthLoginRoute
  '/_app/$teamId/': typeof AppTeamIdIndexRoute
  '/_auth/onboarding/': typeof AuthOnboardingIndexRoute
  '/_app/$teamId/reports/$reportId': typeof AppTeamIdReportsReportIdRouteRouteWithChildren
  '/_app/$teamId/account/settings': typeof AppTeamIdAccountSettingsRoute
  '/_app/$teamId/setup/embed-code': typeof AppTeamIdSetupEmbedCodeRoute
  '/_app/$teamId/setup/extension': typeof AppTeamIdSetupExtensionRoute
  '/_app/r/$shareId/details': typeof AppRShareIdDetailsRoute
  '/_app/r/$shareId/timeline': typeof AppRShareIdTimelineRoute
  '/_app/$teamId/integrations/': typeof AppTeamIdIntegrationsIndexRoute
  '/_app/$teamId/setup/': typeof AppTeamIdSetupIndexRoute
  '/_app/$teamId/subscription/': typeof AppTeamIdSubscriptionIndexRoute
  '/_app/$teamId/team/': typeof AppTeamIdTeamIndexRoute
  '/_app/r/$shareId/': typeof AppRShareIdIndexRoute
  '/_app/$teamId/reports/$reportId/details': typeof AppTeamIdReportsReportIdDetailsRoute
  '/_app/$teamId/reports/$reportId/timeline': typeof AppTeamIdReportsReportIdTimelineRoute
  '/_app/$teamId/subscription/checkout/$transactionId': typeof AppTeamIdSubscriptionCheckoutTransactionIdRoute
  '/_app/$teamId/team/memberships/$membershipId': typeof AppTeamIdTeamMembershipsMembershipIdRoute
  '/_app/$teamId/team/memberships/create': typeof AppTeamIdTeamMembershipsCreateRoute
  '/_app/r/$shareId/network/$eventId': typeof AppRShareIdNetworkEventIdRoute
  '/_app/$teamId/reports/$reportId/': typeof AppTeamIdReportsReportIdIndexRoute
  '/_app/r/$shareId/network/': typeof AppRShareIdNetworkIndexRoute
  '/_app/$teamId/integrations/linear/$linearConnectionId/edit': typeof AppTeamIdIntegrationsLinearLinearConnectionIdEditRoute
  '/_app/$teamId/integrations/slack/$slackConnectionId/edit': typeof AppTeamIdIntegrationsSlackSlackConnectionIdEditRoute
  '/_app/$teamId/integrations/trello/$trelloConnectionId/edit': typeof AppTeamIdIntegrationsTrelloTrelloConnectionIdEditRoute
  '/_app/$teamId/reports/$reportId/network/$eventId': typeof AppTeamIdReportsReportIdNetworkEventIdRoute
  '/_app/$teamId/reports/$reportId/network/': typeof AppTeamIdReportsReportIdNetworkIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/switch-team'
    | '/test'
    | '/$teamId'
    | '/setup-profile'
    | '/invitation/$membershipId'
    | '/widget/$captureKey'
    | '/pay'
    | '/widget'
    | '/r/$shareId'
    | '/extension/post-install'
    | '/t/$'
    | '/auth/login'
    | '/$teamId/'
    | '/onboarding'
    | '/$teamId/reports/$reportId'
    | '/$teamId/account/settings'
    | '/$teamId/setup/embed-code'
    | '/$teamId/setup/extension'
    | '/r/$shareId/details'
    | '/r/$shareId/timeline'
    | '/$teamId/integrations'
    | '/$teamId/setup'
    | '/$teamId/subscription'
    | '/$teamId/team'
    | '/r/$shareId/'
    | '/$teamId/reports/$reportId/details'
    | '/$teamId/reports/$reportId/timeline'
    | '/$teamId/subscription/checkout/$transactionId'
    | '/$teamId/team/memberships/$membershipId'
    | '/$teamId/team/memberships/create'
    | '/r/$shareId/network/$eventId'
    | '/$teamId/reports/$reportId/'
    | '/r/$shareId/network'
    | '/$teamId/integrations/linear/$linearConnectionId/edit'
    | '/$teamId/integrations/slack/$slackConnectionId/edit'
    | '/$teamId/integrations/trello/$trelloConnectionId/edit'
    | '/$teamId/reports/$reportId/network/$eventId'
    | '/$teamId/reports/$reportId/network'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/switch-team'
    | '/test'
    | '/setup-profile'
    | '/invitation/$membershipId'
    | '/widget/$captureKey'
    | '/pay'
    | '/widget'
    | '/extension/post-install'
    | '/t/$'
    | '/auth/login'
    | '/$teamId'
    | '/onboarding'
    | '/$teamId/account/settings'
    | '/$teamId/setup/embed-code'
    | '/$teamId/setup/extension'
    | '/r/$shareId/details'
    | '/r/$shareId/timeline'
    | '/$teamId/integrations'
    | '/$teamId/setup'
    | '/$teamId/subscription'
    | '/$teamId/team'
    | '/r/$shareId'
    | '/$teamId/reports/$reportId/details'
    | '/$teamId/reports/$reportId/timeline'
    | '/$teamId/subscription/checkout/$transactionId'
    | '/$teamId/team/memberships/$membershipId'
    | '/$teamId/team/memberships/create'
    | '/r/$shareId/network/$eventId'
    | '/$teamId/reports/$reportId'
    | '/r/$shareId/network'
    | '/$teamId/integrations/linear/$linearConnectionId/edit'
    | '/$teamId/integrations/slack/$slackConnectionId/edit'
    | '/$teamId/integrations/trello/$trelloConnectionId/edit'
    | '/$teamId/reports/$reportId/network/$eventId'
    | '/$teamId/reports/$reportId/network'
  id:
    | '__root__'
    | '/'
    | '/_app'
    | '/_auth'
    | '/switch-team'
    | '/test'
    | '/_app/$teamId'
    | '/_auth/setup-profile'
    | '/invitation/$membershipId'
    | '/widget/$captureKey'
    | '/pay/'
    | '/widget/'
    | '/_app/r/$shareId'
    | '/_app/extension/post-install'
    | '/_app/t/$'
    | '/_auth/auth/login'
    | '/_app/$teamId/'
    | '/_auth/onboarding/'
    | '/_app/$teamId/reports/$reportId'
    | '/_app/$teamId/account/settings'
    | '/_app/$teamId/setup/embed-code'
    | '/_app/$teamId/setup/extension'
    | '/_app/r/$shareId/details'
    | '/_app/r/$shareId/timeline'
    | '/_app/$teamId/integrations/'
    | '/_app/$teamId/setup/'
    | '/_app/$teamId/subscription/'
    | '/_app/$teamId/team/'
    | '/_app/r/$shareId/'
    | '/_app/$teamId/reports/$reportId/details'
    | '/_app/$teamId/reports/$reportId/timeline'
    | '/_app/$teamId/subscription/checkout/$transactionId'
    | '/_app/$teamId/team/memberships/$membershipId'
    | '/_app/$teamId/team/memberships/create'
    | '/_app/r/$shareId/network/$eventId'
    | '/_app/$teamId/reports/$reportId/'
    | '/_app/r/$shareId/network/'
    | '/_app/$teamId/integrations/linear/$linearConnectionId/edit'
    | '/_app/$teamId/integrations/slack/$slackConnectionId/edit'
    | '/_app/$teamId/integrations/trello/$trelloConnectionId/edit'
    | '/_app/$teamId/reports/$reportId/network/$eventId'
    | '/_app/$teamId/reports/$reportId/network/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRouteRoute: typeof AppRouteRouteWithChildren
  AuthRouteRoute: typeof AuthRouteRouteWithChildren
  SwitchTeamRoute: typeof SwitchTeamRoute
  TestRoute: typeof TestRoute
  InvitationMembershipIdRoute: typeof InvitationMembershipIdRoute
  WidgetCaptureKeyRoute: typeof WidgetCaptureKeyRoute
  PayIndexRoute: typeof PayIndexRoute
  WidgetIndexRoute: typeof WidgetIndexRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRouteRoute: AppRouteRouteWithChildren,
  AuthRouteRoute: AuthRouteRouteWithChildren,
  SwitchTeamRoute: SwitchTeamRoute,
  TestRoute: TestRoute,
  InvitationMembershipIdRoute: InvitationMembershipIdRoute,
  WidgetCaptureKeyRoute: WidgetCaptureKeyRoute,
  PayIndexRoute: PayIndexRoute,
  WidgetIndexRoute: WidgetIndexRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/_auth",
        "/switch-team",
        "/test",
        "/invitation/$membershipId",
        "/widget/$captureKey",
        "/pay/",
        "/widget/"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_app": {
      "filePath": "_app/route.tsx",
      "children": [
        "/_app/$teamId",
        "/_app/r/$shareId",
        "/_app/extension/post-install",
        "/_app/t/$"
      ]
    },
    "/_auth": {
      "filePath": "_auth/route.tsx",
      "children": [
        "/_auth/setup-profile",
        "/_auth/auth/login",
        "/_auth/onboarding/"
      ]
    },
    "/switch-team": {
      "filePath": "switch-team.tsx"
    },
    "/test": {
      "filePath": "test.tsx"
    },
    "/_app/$teamId": {
      "filePath": "_app/$teamId/route.tsx",
      "parent": "/_app",
      "children": [
        "/_app/$teamId/",
        "/_app/$teamId/reports/$reportId",
        "/_app/$teamId/account/settings",
        "/_app/$teamId/setup/embed-code",
        "/_app/$teamId/setup/extension",
        "/_app/$teamId/integrations/",
        "/_app/$teamId/setup/",
        "/_app/$teamId/subscription/",
        "/_app/$teamId/team/",
        "/_app/$teamId/subscription/checkout/$transactionId",
        "/_app/$teamId/team/memberships/$membershipId",
        "/_app/$teamId/team/memberships/create",
        "/_app/$teamId/integrations/linear/$linearConnectionId/edit",
        "/_app/$teamId/integrations/slack/$slackConnectionId/edit",
        "/_app/$teamId/integrations/trello/$trelloConnectionId/edit"
      ]
    },
    "/_auth/setup-profile": {
      "filePath": "_auth/setup-profile.tsx",
      "parent": "/_auth"
    },
    "/invitation/$membershipId": {
      "filePath": "invitation/$membershipId.tsx"
    },
    "/widget/$captureKey": {
      "filePath": "widget/$captureKey.tsx"
    },
    "/pay/": {
      "filePath": "pay/index.tsx"
    },
    "/widget/": {
      "filePath": "widget/index.tsx"
    },
    "/_app/r/$shareId": {
      "filePath": "_app/r/$shareId/route.tsx",
      "parent": "/_app",
      "children": [
        "/_app/r/$shareId/details",
        "/_app/r/$shareId/timeline",
        "/_app/r/$shareId/",
        "/_app/r/$shareId/network/$eventId",
        "/_app/r/$shareId/network/"
      ]
    },
    "/_app/extension/post-install": {
      "filePath": "_app/extension/post-install.tsx",
      "parent": "/_app"
    },
    "/_app/t/$": {
      "filePath": "_app/t/$.tsx",
      "parent": "/_app"
    },
    "/_auth/auth/login": {
      "filePath": "_auth/auth/login.tsx",
      "parent": "/_auth"
    },
    "/_app/$teamId/": {
      "filePath": "_app/$teamId/index.tsx",
      "parent": "/_app/$teamId"
    },
    "/_auth/onboarding/": {
      "filePath": "_auth/onboarding/index.tsx",
      "parent": "/_auth"
    },
    "/_app/$teamId/reports/$reportId": {
      "filePath": "_app/$teamId/reports/$reportId/route.tsx",
      "parent": "/_app/$teamId",
      "children": [
        "/_app/$teamId/reports/$reportId/details",
        "/_app/$teamId/reports/$reportId/timeline",
        "/_app/$teamId/reports/$reportId/",
        "/_app/$teamId/reports/$reportId/network/$eventId",
        "/_app/$teamId/reports/$reportId/network/"
      ]
    },
    "/_app/$teamId/account/settings": {
      "filePath": "_app/$teamId/account/settings.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/setup/embed-code": {
      "filePath": "_app/$teamId/setup/embed-code.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/setup/extension": {
      "filePath": "_app/$teamId/setup/extension.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/r/$shareId/details": {
      "filePath": "_app/r/$shareId/details.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/r/$shareId/timeline": {
      "filePath": "_app/r/$shareId/timeline.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/$teamId/integrations/": {
      "filePath": "_app/$teamId/integrations/index.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/setup/": {
      "filePath": "_app/$teamId/setup/index.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/subscription/": {
      "filePath": "_app/$teamId/subscription/index.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/team/": {
      "filePath": "_app/$teamId/team/index.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/r/$shareId/": {
      "filePath": "_app/r/$shareId/index.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/$teamId/reports/$reportId/details": {
      "filePath": "_app/$teamId/reports/$reportId/details.tsx",
      "parent": "/_app/$teamId/reports/$reportId"
    },
    "/_app/$teamId/reports/$reportId/timeline": {
      "filePath": "_app/$teamId/reports/$reportId/timeline.tsx",
      "parent": "/_app/$teamId/reports/$reportId"
    },
    "/_app/$teamId/subscription/checkout/$transactionId": {
      "filePath": "_app/$teamId/subscription/checkout/$transactionId.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/team/memberships/$membershipId": {
      "filePath": "_app/$teamId/team/memberships/$membershipId.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/team/memberships/create": {
      "filePath": "_app/$teamId/team/memberships/create.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/r/$shareId/network/$eventId": {
      "filePath": "_app/r/$shareId/network.$eventId.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/$teamId/reports/$reportId/": {
      "filePath": "_app/$teamId/reports/$reportId/index.tsx",
      "parent": "/_app/$teamId/reports/$reportId"
    },
    "/_app/r/$shareId/network/": {
      "filePath": "_app/r/$shareId/network.index.tsx",
      "parent": "/_app/r/$shareId"
    },
    "/_app/$teamId/integrations/linear/$linearConnectionId/edit": {
      "filePath": "_app/$teamId/integrations/linear/$linearConnectionId/edit.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/integrations/slack/$slackConnectionId/edit": {
      "filePath": "_app/$teamId/integrations/slack/$slackConnectionId/edit.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/integrations/trello/$trelloConnectionId/edit": {
      "filePath": "_app/$teamId/integrations/trello/$trelloConnectionId/edit.tsx",
      "parent": "/_app/$teamId"
    },
    "/_app/$teamId/reports/$reportId/network/$eventId": {
      "filePath": "_app/$teamId/reports/$reportId/network.$eventId.tsx",
      "parent": "/_app/$teamId/reports/$reportId"
    },
    "/_app/$teamId/reports/$reportId/network/": {
      "filePath": "_app/$teamId/reports/$reportId/network.index.tsx",
      "parent": "/_app/$teamId/reports/$reportId"
    }
  }
}
ROUTE_MANIFEST_END */
