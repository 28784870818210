const formTags = ["input", "select", "textarea"];
const attributes = [
    "id",
    "name",
    "class",
    "type",
    "placeholder",
    "role",
    "href",
    "data-testid",
];
const truncate = (value) => {
    if (value.length > 200) {
        return value.slice(0, 200) + "…";
    }
    return value;
};
const getAttributes = (element) => {
    const output = {};
    attributes.forEach((attribute) => {
        const value = element.getAttribute(attribute)?.trim();
        if (value) {
            output[attribute] = truncate(value);
        }
    });
    return output;
};
const getElementName = (element) => {
    const labelledBy = element.getAttribute("aria-labelledby");
    if (labelledBy) {
        const labelledByElement = document.getElementById(labelledBy);
        if (labelledByElement) {
            return labelledByElement.textContent ?? undefined;
        }
    }
    const name = element.getAttribute("aria-label");
    if (name) {
        return name.trim();
    }
    const tagName = element.tagName.toLowerCase();
    if (formTags.includes(tagName)) {
        const id = element.getAttribute("id");
        if (id) {
            const label = document.querySelector(`label[for="${id}"]`);
            if (label) {
                return getElementName(label);
            }
        }
        const placeholder = element.getAttribute("placeholder");
        if (placeholder) {
            return placeholder.trim();
        }
    }
    if (element.matches("button,[role=button],a,[role=link],option,[role=option]")) {
        return element.textContent?.trim() ?? undefined;
    }
    return undefined;
};
const interativeSelector = "button, a, [role=button], [role=link]";
export const getElementInfo = (target) => {
    const interactiveParent = target.closest(interativeSelector);
    const element = interactiveParent ?? target;
    const name = getElementName(element);
    return {
        tagName: element.tagName.toLowerCase(),
        name: name ? truncate(name) : undefined,
        attributes: getAttributes(element),
    };
};
