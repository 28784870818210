import { cx } from "@emotion/css";
import React from "react";

import * as styles from "./container.styles";

export interface ContainerProps {
  fullHeight?: boolean;
  verticallyCentered?: boolean;
  children?: React.ReactNode;
  size?: "sm" | "md" | "lg";
  className?: string;
  hasPadding?: boolean;
}

export const Container = ({
  fullHeight,
  verticallyCentered,
  size = "lg",
  children,
  className,
  hasPadding,
}: ContainerProps) => {
  return (
    <div
      className={cx(styles.container, className, {
        [styles.fullHeight]: fullHeight,
        [styles.verticalCenter]: verticallyCentered,
      })}
    >
      <div
        className={cx(styles.inner, styles[size], {
          [styles.padded]: hasPadding,
        })}
      >
        {children}
      </div>
    </div>
  );
};
