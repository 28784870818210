import { z } from "zod";

const SerializedShadowRootBase = z.object({
  type: z.literal("shadow"),
  adoptedStylesheets: z.array(z.number()),
});

export type SerializedShadowRoot = z.infer<typeof SerializedShadowRootBase> & {
  children: SerializedChildNode[];
};

export const SerializedShadowRoot: z.ZodType<SerializedShadowRoot> =
  SerializedShadowRootBase.extend({
    children: z.lazy(() => z.array(SerializedChildNode)),
  });

const SerializedElementNodeBase = z.object({
  type: z.literal("el"),
  isSvg: z.boolean().optional(),
  tag: z.string(),
  attr: z.record(z.string()),
  scroll: z.tuple([z.number(), z.number()]).optional(),
});

export type SerializedElementNode = z.infer<
  typeof SerializedElementNodeBase
> & {
  children: SerializedChildNode[];
  shadow?: SerializedShadowRoot;
};

export const SerializedElementNode: z.ZodType<SerializedElementNode> =
  SerializedElementNodeBase.extend({
    shadow: z.lazy(() => SerializedShadowRoot.optional()),
    children: z.lazy(() => z.array(SerializedChildNode)),
  });

export const SerializedTextNode = z.object({
  type: z.literal("text"),
  content: z.string().nullable(),
});
export type SerializedTextNode = z.infer<typeof SerializedTextNode>;

export const SerializedStyleSheet = z.object({
  id: z.number(),
  href: z.string().optional(),
  rules: z.array(z.string()),
});
export type SerializedStyleSheet = z.infer<typeof SerializedStyleSheet>;

export const SerializedStyleNode = z.object({
  type: z.literal("style"),
  sheetId: z.number(),
});
export type SerializedStyleNode = z.infer<typeof SerializedStyleNode>;

export const SerializedDocumentNode = z.object({
  type: z.literal("doc"),
  // All possible stylesheets that can be adopted by the doc, or by a shadow root
  stylesheets: z.array(SerializedStyleSheet),
  adoptedStylesheets: z.array(z.number()),
  root: SerializedElementNode,
});
export type SerializedDocumentNode = z.infer<typeof SerializedDocumentNode>;

export const SerializedChildNode = z.union([
  SerializedElementNode,
  SerializedTextNode,
  SerializedStyleNode,
]);
export type SerializedChildNode = z.infer<typeof SerializedChildNode>;
