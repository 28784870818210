import { Spinner } from "@components/spinner/spinner.js";

import * as styles from "./report-viewer-skeleton.styles";

export const ReportViewerSkeleton = () => {
  return (
    <div className={styles.root}>
      <Spinner size={32} />
    </div>
  );
};
