import React, { Component } from "react";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  renderFallback: (props: {
    error: Error;
    resetErrorBoundary: () => void;
  }) => React.ReactNode;
}

interface ErrorBoundaryState {
  error: unknown;
}

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: unknown) {
    return { error };
  }

  componentDidCatch(error: unknown) {
    // Some ad blockers may block Sentry
    if ("Sentry" in window) {
      //@ts-expect-error -- using the global sentry object
      Sentry.captureException(error);
    }
  }

  render() {
    if (this.state.error instanceof Error) {
      // You can render any custom fallback UI
      return this.props.renderFallback({
        error: this.state.error,
        resetErrorBoundary: () => {
          this.setState({ error: null });
        },
      });
    }
    return this.props.children;
  }
}
