import { z } from "zod";

export const Stage = z.enum(["local", "production"]);
export type Stage = z.infer<typeof Stage>;

const Config = z.object({
  WEBAPP_ORIGIN: z.string(),
  SERVER_ORIGIN: z.string(),
  WEBSITE_ORIGIN: z.string(),
  TERMS_URL: z.string(),
  PRIVACY_URL: z.string(),
  STAGE: Stage,
  PADDLE_CLIENT_TOKEN: z.string(),
  AMPLITUDE_API_KEY: z.string(),
  CAPTURE_KEY: z.string(),
});
export type Config = z.infer<typeof Config>;

/**
 * Values also need to be added in vite.config.ts
 */
export const config = Config.parse({
  WEBAPP_ORIGIN: import.meta.env.WEBAPP_ORIGIN,
  SERVER_ORIGIN: import.meta.env.SERVER_ORIGIN,
  WEBSITE_ORIGIN: import.meta.env.WEBSITE_ORIGIN,
  TERMS_URL: import.meta.env.TERMS_URL,
  PRIVACY_URL: import.meta.env.PRIVACY_URL,
  STAGE: import.meta.env.STAGE,
  PADDLE_CLIENT_TOKEN: import.meta.env.PADDLE_CLIENT_TOKEN,
  AMPLITUDE_API_KEY: import.meta.env.AMPLITUDE_API_KEY,
  CAPTURE_KEY: import.meta.env.VITE_CAPTURE_KEY,
});
