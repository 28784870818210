import { REDACTED, } from "../client/privacy-options.js";
const requestEventToProperties = (event) => ({
    error: event.extra.error,
    url: event.url,
    request: {
        headers: event.request.headers,
        method: event.request.method,
        body: event.extra.request.body,
    },
    response: event.response && event.extra.response
        ? {
            headers: event.response.headers,
            body: event.extra.response.body,
            status: event.response.status,
        }
        : undefined,
});
const redactRequestHeaders = (fn, headers, event) => Object.fromEntries(Object.entries(headers).map(([key, value]) => {
    if (key === "authorization" ||
        String(value).toLowerCase().startsWith("bearer")) {
        return [key, REDACTED];
    }
    return fn({ key, value, event });
}));
const redactRequestBody = (fn, body, event) => {
    if (body === undefined) {
        return undefined;
    }
    return fn({ event }) ? body : REDACTED;
};
const redactRequestEvent = (event, privacyFunctions) => {
    const properties = requestEventToProperties(event);
    return {
        ...event,
        request: {
            ...event.request,
            body: redactRequestBody(privacyFunctions.requestBody, event.request.body, properties),
            headers: redactRequestHeaders(privacyFunctions.requestHeader, event.request.headers, properties),
        },
        response: event.response
            ? {
                ...event.response,
                body: redactRequestBody(privacyFunctions.responseBody, event.response.body, properties),
                headers: redactRequestHeaders(privacyFunctions.responseHeader, event.response.headers, properties),
            }
            : undefined,
    };
};
const redactElementAttributes = (fn, attributes, element) => {
    return Object.fromEntries(Object.entries(attributes).map(([key, value]) => fn({ key, value, element })));
};
const redactInputValue = (fn, value, element) => {
    if (element.getAttribute("type") === "password") {
        return REDACTED;
    }
    return fn({ element, value }) ? value : REDACTED;
};
const redactElementEvent = (event, privacyFunctions) => {
    const output = {
        ...event,
        element: {
            ...event.element,
            name: privacyFunctions.elementName({
                element: event.extra.target,
                name: event.element.name,
            }),
            attributes: redactElementAttributes(privacyFunctions.elementAttribute, event.element.attributes, event.extra.target),
        },
    };
    if (output.type === "change") {
        output.value = redactInputValue(privacyFunctions.inputValue, output.value, event.extra.target);
    }
    return output;
};
export const redactEvent = (event, privacyFunctions) => {
    switch (event.type) {
        case "request": {
            return redactRequestEvent(event, privacyFunctions);
        }
        case "click":
        case "change":
        case "focus":
        case "submit": {
            return redactElementEvent(event, privacyFunctions);
        }
        default:
            return event;
    }
};
