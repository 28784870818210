import { DarkModeProvider } from "@features/dark-mode/dark-mode-provider.js";
import { ErrorBoundary } from "@features/error-boundary/error-boundary.js";
import { GlobalLoader } from "@features/global-loader/global-loader.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRootRoute, Outlet } from "@tanstack/react-router";
import { Suspense, useState } from "react";

import { PageError } from "../features/page-error/page-error.js";
import { GlobalStyles } from "../styles/global-styles.js";

import "../utils/capture-client.js";

export const Route = createRootRoute({
  component: () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [queryClient] = useState(() => new QueryClient());
    return (
      <>
        <GlobalStyles />
        <ErrorBoundary
          renderFallback={({ resetErrorBoundary }) => (
            <PageError resetErrorBoundary={resetErrorBoundary} />
          )}
        >
          <DarkModeProvider>
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<GlobalLoader />}>
                <Outlet />
              </Suspense>
            </QueryClientProvider>
          </DarkModeProvider>
        </ErrorBoundary>
      </>
    );
  },
});
