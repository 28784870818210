import { z } from "zod";

export const LinearSettingsFormValues = z.object({
  linearConnectionId: z.string(),
  autoCreateIssues: z.boolean(),
  defaultTeamId: z.string(),
  defaultWorkflowStatusId: z.string().nullable(),
});

export type LinearSettingsFormValues = z.infer<typeof LinearSettingsFormValues>;
