import { css } from "@emotion/css";
import {
  color,
  radii,
  space,
  controlHeight,
  typography,
  fontWeight,
} from "@styles";

export const button = css`
  --button-background-disabled: var(--button-background);
  background: var(--button-background);
  padding: 0 var(--button-padding-x);
  border-radius: var(--button-radius);
  color: var(--button-color);
  height: var(--button-height);
  border: var(--button-border);
  font: var(--button-font);
  font-weight: var(--button-font-weight);
  text-decoration: none;
  font-family: inherit;
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  white-space: nowrap;
  position: relative;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background: var(--button-hover);
  }

  svg {
    width: var(--button-icon-size);
    height: var(--button-icon-size);
  }

  &[data-state="off"] {
    background: var(--button-background-unselected);
  }
`;

export const skeleton = css`
  pointer-events: none;
`;

export const content = css`
  display: inline-flex;
  align-items: center;
  gap: ${space(1)};
`;

export const left = css`
  justify-content: flex-start;
`;

export const center = css`
  justify-content: center;
`;

export const right = css`
  justify-content: flex-end;
`;

export const secondary = css`
  --button-border: 1px solid ${color("primaryBorder")};
  --button-background: ${color("primary")};
  --button-hover: ${color("primaryHover")};
  --button-color-disabled: ${color("textDisabled")};
  --button-background-unselected: ${color("muted")};
`;

export const contrast = css`
  --button-background: ${color("contrast")};
  --button-color: ${color("textInverse")};
  --button-hover: ${color("contrastHover")};
`;

export const complementary = css`
  --button-background: ${color("complementary")};
  --button-color: ${color("textInverse")};
  --button-hover: ${color("complementaryHover")};
`;

export const criticalSecondary = css`
  --button-border: 1px solid ${color("criticalMutedBorder")};
  --button-background: ${color("primary")};
  --button-hover: ${color("primaryHover")};
  --button-color-disabled: ${color("textDisabled")};
  --button-color: ${color("critical")};
`;

export const subtle = css`
  --button-background: var(--subtle);
  --button-hover: ${color("muted")};
  --button-background-selected: ${color("muted")};
  --button-color: ${color("textPrimary")};
`;

export const criticalSubtle = css`
  --button-background: var(--subtle);
  --button-hover: ${color("criticalMuted")};
  --button-color: ${color("textCritical")};
`;

export const critical = css`
  --button-background: ${color("critical")};
  --button-hover: ${color("criticalHover")};
  --button-color: ${color("textInverse")};
`;

export const criticalMuted = css`
  --button-background: ${color("criticalMuted")};
  --button-color: ${color("critical")};
  --button-hover: ${color("criticalMutedHover")};
`;

export const fillContainer = css`
  width: 100%;
`;

export const iconOnly = css`
  padding: 0;
  width: var(--button-height);
  min-width: var(--button-height);
`;

export const isRound = css`
  border-radius: 100px;
`;

export const xs = css`
  --button-radius: ${radii.sm};
  --button-padding-x: ${space(1)};
  --button-height: ${controlHeight.xs};
  --button-icon-size: 14px;
`;

export const sm = css`
  --button-radius: ${radii.sm};
  --button-padding-x: ${space(1.5)};
  --button-height: ${controlHeight.sm};
  --button-icon-size: 16px;
  --button-font: ${typography.body3};
  --button-font-weight: ${fontWeight.regular};
  min-width: 80px;
`;

export const md = css`
  --button-radius: ${radii.sm};
  --button-padding-x: ${space(2.5)};
  --button-height: ${controlHeight.md};
  --button-icon-size: 18px;
  --button-font: ${typography.body3};
  --button-font-weight: ${fontWeight.bold};
`;

export const lg = css`
  --button-radius: ${radii.md};
  --button-padding-x: ${space(3)};
  --button-height: ${controlHeight.lg};
  --button-icon-size: 18px;
  --button-font: ${typography.body3};
  --button-font-weight: ${fontWeight.bold};
`;

export const selected = css`
  --button-background: var(--button-background-selected);
`;

export const loading = css`
  opacity: 0;
`;

export const spinner = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const selectedIcon = css`
  width: ${controlHeight.xxs};
  height: ${controlHeight.xxs};
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  background: ${color("accent")};
`;

export const fontWeightNormal = css`
  font-weight: normal;
`;

export const fontWeightBold = css`
  font-weight: bold;
`;
