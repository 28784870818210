import { scale, hex } from "chroma-js";

import { ThemeColors } from "../constants.js";

const tint = "#2e6ffc";

const greyScale = scale(["#111111", "#ffffff"]);

const primary = greyScale(0.03).mix(tint, 0.0075);
const secondary = greyScale(0.0).mix(tint, 0.0025);
const tertiary = greyScale(0.02).mix(tint, 0.0075);
const contrast = greyScale(1);

const accent = "#BDEE63";
const complementary = hex("#2e6ffc");

const muted = greyScale(0.2).mix(tint, 0.1).alpha(0.4);
const overlay = "#00000080";

const backgroundSkeleton = "#e8ebf0";

const success = hex("#3E9B4F");
const warning = hex("#f59e0c");
const critical = hex("#dc2625");

const textWarning = hex("#d9760c");

export const darkColors = {
  primary: primary.hex(),
  primaryHover: primary.brighten(0.1).hex(),
  primaryBorder: primary.brighten(0.7).hex(),
  secondary: secondary.hex(),
  secondaryHover: secondary.brighten(0.2).hex(),
  tertiary: tertiary.hex(),
  tertiaryHover: tertiary.brighten(0.01).hex(),
  secondaryBorder: secondary.brighten(0.7).hex(),
  muted: muted.hex(),
  overlay,
  textPrimary: greyScale(1).hex(),
  textSecondary: greyScale(0.6).mix(tint, 0.1).hex(),
  textInverse: greyScale(0).hex(),
  textInverseSecondary: greyScale(0.2).hex(),
  textPlaceholder: greyScale(0.4).mix(tint, 0.1).hex(),
  textDisabled: greyScale(0.4).hex(),
  accent,
  complementary: complementary.hex(),
  complementaryHover: complementary.brighten(0.01).hex(),
  success: success.hex(),
  successMuted: success.alpha(0.1).hex(),
  textSuccess: success.brighten(0.5).hex(),
  textOnSuccess: "#ffffff",
  contrast: contrast.hex(),
  contrastHover: contrast.darken(0.4).hex(),
  backgroundSkeleton,
  warning: warning.hex(),
  textWarning: textWarning.brighten(0.8).hex(),
  textWarningSecondary: textWarning.hex(),
  critical: critical.hex(),
  textOnCritical: "#ffffff",
  criticalMuted: critical.alpha(0.1).hex(),
  criticalMutedHover: critical.alpha(0.2).hex(),
  criticalHover: critical.brighten(0.2).hex(),
  criticalMutedBorder: critical.alpha(0.5).hex(),
  textCritical: critical.brighten(0.8).hex(),
  textCriticalSecondary: critical.hex(),
} satisfies ThemeColors;
