import { z } from "zod";

import { BaseEvent } from "./base-event.js";
import { ErrorInfo } from "./error-info.js";
import { EventLevel } from "./event-types.js";
import { Redacted } from "./redacted.js";

export const NetworkInitiator = z.enum([
  "audio",
  "beacon",
  "body",
  "css",
  "early-hint",
  "embed",
  "fetch",
  "frame",
  "iframe",
  "icon",
  "image",
  "img",
  "input",
  "link",
  "navigation",
  "object",
  "ping",
  "script",
  "track",
  "video",
  "xmlhttprequest",
  "uacss",
  "other",
]);
export type NetworkInitiator = z.infer<typeof NetworkInitiator>;

export const TextBody = z.object({
  type: z.literal("text"),
  text: z.string(),
  isTruncated: z.boolean().optional(),
});
export type TextBody = z.infer<typeof TextBody>;

export const FileDesc = z.object({
  type: z.literal("file"),
  name: z.string(),
  size: z.number(),
  mimeType: z.string(),
});
export type FileDesc = z.infer<typeof FileDesc>;

export const FormDataBody = z.object({
  type: z.literal("form-data"),
  data: z.record(z.union([z.string(), FileDesc])),
});
export type FormDataBody = z.infer<typeof FormDataBody>;

export const UrlSearchParamsBody = z.object({
  type: z.literal("url-search-params"),
  data: z.record(z.string()),
});
export type UrlSearchParamsBody = z.infer<typeof UrlSearchParamsBody>;

export const ArrayBufferBody = z.object({
  type: z.literal("array-buffer"),
  byteLength: z.number(),
});
export type ArrayBufferBody = z.infer<typeof ArrayBufferBody>;

export const BlobBody = z.object({
  type: z.literal("blob"),
  size: z.number(),
  mimeType: z.string(),
});
export type BlobBody = z.infer<typeof BlobBody>;

export const UnknownBody = z.object({
  type: z.literal("unknown"),
});
export type UnknownBody = z.infer<typeof UnknownBody>;

export const RequestBody = z.union([
  FormDataBody,
  UrlSearchParamsBody,
  TextBody,
  ArrayBufferBody,
  BlobBody,
  UnknownBody,
  Redacted,
]);
export type RequestBody = z.infer<typeof RequestBody>;

export const OtherBodyType = z.object({
  type: z.literal("other"),
  size: z.number().optional(),
});
export type OtherBodyType = z.infer<typeof OtherBodyType>;

export const ResponseBody = z.union([TextBody, OtherBodyType, Redacted]);
export type ResponseBody = z.infer<typeof ResponseBody>;

export const NetworkCategory = z.enum([
  "request",
  "image",
  "media",
  "font",
  "script",
  "stylesheet",
  "document",
  "other",
]);
export type NetworkCategory = z.infer<typeof NetworkCategory>;

const NetworkHeaders = z.record(z.coerce.string());

export const NetworkResponse = z.object({
  status: z.number(),
  headers: NetworkHeaders,
  body: ResponseBody.optional(),
});
export type NetworkResponse = z.infer<typeof NetworkResponse>;

export const NetworkRequest = z.object({
  headers: NetworkHeaders,
  body: RequestBody.optional(),
  method: z.string(),
});
export type NetworkRequest = z.infer<typeof NetworkRequest>;

export const ResourceEvent = BaseEvent.extend({
  type: z.literal("resource"),
  initiator: z.union([NetworkInitiator, z.string()]),
  url: z.string(),
  startTime: z.number(),
  endTime: z.number(),
  transferSize: z.number(),
  encodedBodySize: z.number().optional(),
  decodedBodySize: z.number().optional(),
});
export type ResourceEvent = z.infer<typeof ResourceEvent>;

export const GraphQLProperties = z.object({
  operationName: z.string().optional(),
});

export const RequestInitiator = z.enum(["fetch", "xhr"]);

export const RequestEvent = BaseEvent.extend({
  type: z.literal("request"),
  url: z.string(),
  error: ErrorInfo.nullish(),
  initiator: RequestInitiator,
  startTime: z.number(),
  endTime: z.number().optional(),
  request: NetworkRequest,
  response: NetworkResponse.optional(),
  graphQL: GraphQLProperties.optional(),
});
export type RequestEvent = z.infer<typeof RequestEvent>;

export const NetworkEvent = z.union([ResourceEvent, RequestEvent]);
export type NetworkEvent = z.infer<typeof NetworkEvent>;

export const isNetworkEvent = (event: unknown): event is NetworkEvent => {
  return (
    typeof event === "object" &&
    event !== null &&
    "type" in event &&
    typeof event["type"] === "string" &&
    ["resource", "request"].includes(event["type"])
  );
};

/**
 * Events that have been parsed on the server after they are received
 */

export const ParsedResourceEvent = ResourceEvent.extend({
  relativePath: z.string().optional(),
  category: NetworkCategory,
  level: EventLevel,
});
export type ParsedResourceEvent = z.infer<typeof ParsedResourceEvent>;

export const ParsedRequestEvent = RequestEvent.extend({
  relativePath: z.string().optional(),
  category: NetworkCategory,
  level: EventLevel,
});
export type ParsedRequestEvent = z.infer<typeof ParsedRequestEvent>;

export const ParsedNetworkEvent = z.union([
  ParsedResourceEvent,
  ParsedRequestEvent,
]);
export type ParsedNetworkEvent = z.infer<typeof ParsedNetworkEvent>;
