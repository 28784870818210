import { cx } from "@emotion/css";
import { forwardRef } from "react";

import * as styles from "./badge.styles";

export type BadgeVariant =
  | "muted"
  | "danger"
  | "subtle"
  | "contrast"
  | "success";

export type BadgeSize = "xs" | "sm" | "md" | "lg" | "xl";

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement> {
  isRound?: boolean;
  isSquare?: boolean;
  iconOnly?: boolean;
  isMono?: boolean;
  size?: BadgeSize;
  children?: React.ReactNode;
  variant?: BadgeVariant;
  hasBorder?: boolean;
  shouldFillContainer?: boolean;
}

export const Badge = forwardRef<HTMLDivElement, BadgeProps>(
  (
    {
      children,
      isRound,
      isSquare,
      iconOnly,
      size = "sm",
      variant = "muted",
      isMono = false,
      shouldFillContainer = false,
      hasBorder,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        ref={ref}
        {...rest}
        className={cx(
          rest.className,
          styles.badge,
          styles[size],
          styles[variant],
          {
            [styles.hasBorder]: hasBorder,
            [styles.square]: isSquare,
            [styles.round]: isRound,
            [styles.iconOnly]: iconOnly,
            [styles.fillContainer]: shouldFillContainer,
            [styles.isMono]: isMono,
          }
        )}
      >
        {children}
      </div>
    );
  }
);

Badge.displayName = "Badge";
