import { ButtonSkeleton } from "@components/button/button.skeleton.js";
import { ParagraphSkeleton } from "@components/skeleton/paragraph.skeleton.js";
import { TextSkeleton } from "@components/skeleton/text.skeleton.js";

import * as styles from "./IntegrationsCard.styles";

export const IntegrationsCardSkeleton = () => {
  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <TextSkeleton className={styles.title}>Lorem ipsum</TextSkeleton>
        <ParagraphSkeleton />
      </div>
      <div className={styles.footer}>
        <ButtonSkeleton>Lorem ipsum</ButtonSkeleton>
      </div>
    </div>
  );
};
