import { css, keyframes } from "@emotion/css";
import { color, radii } from "@styles";

export const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
`;

export const root = css`
  background: ${color("muted")};
  border-radius: ${radii.md};
  animation: 1.5s ${pulse} linear infinite;
  color: transparent !important;
  > * {
    opacity: 0;
  }
`;

export const stretchToFill = css`
  width: 100%;
  height: 100%;
`;
