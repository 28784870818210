import { z } from "zod";

import { BaseEvent } from "./base-event.js";
import { ErrorInfo } from "./error-info.js";
import { EventLevel } from "./event-types.js";
import { JSONValue } from "./json.js";

export const ConsoleMethod = z.enum(["log", "info", "warn", "error", "debug"]);
export type ConsoleMethod = z.infer<typeof ConsoleMethod>;

export const ErrorArg = z.object({
  __CAPTURE_TYPE__: z.literal("error"),
  error: ErrorInfo,
});
export type ErrorArg = z.infer<typeof ErrorArg>;

export const isErrorArg = (arg: unknown): arg is ErrorArg => {
  return typeof arg === "object" && arg !== null && "__CAPTURE_TYPE__" in arg;
};

export const ConsoleEvent = BaseEvent.extend({
  type: z.literal("console"),
  method: ConsoleMethod,
  args: z.array(z.union([JSONValue, ErrorArg])),
});

export type ConsoleEvent = z.infer<typeof ConsoleEvent>;

export const ParsedConsoleEvent = ConsoleEvent.extend({
  level: EventLevel,
});
export type ParsedConsoleEvent = z.infer<typeof ParsedConsoleEvent>;
