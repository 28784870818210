import { Button } from "@components/button/button.js";
import { useRouterState } from "@tanstack/react-router";
import { captureClient } from "@utils/capture-client.js";
import { BugIcon } from "lucide-react";
import { useEffect, useRef } from "react";

import { GlobalError } from "../global-error/global-error.js";

interface PageErrorProps {
  resetErrorBoundary: () => void;
}

export const PageError = ({ resetErrorBoundary }: PageErrorProps) => {
  const state = useRouterState();
  const previousPathName = useRef(state.location.pathname);
  useEffect(() => {
    if (state.location.pathname !== previousPathName.current) {
      resetErrorBoundary();
      previousPathName.current = state.location.pathname;
    }
  }, [resetErrorBoundary, state.location.pathname]);
  return (
    <GlobalError
      cta={
        <Button variant="secondary" onClick={() => captureClient.open()}>
          <BugIcon />
          Report an issue
        </Button>
      }
    />
  );
};
