import { css } from "@emotion/css";
import { space } from "@styles";

export const root = css`
  padding-top: ${space(6)};
`;

export const header = css`
  margin-bottom: ${space(5)};
`;

export const content = css`
  padding-bottom: ${space(6)};
`;
