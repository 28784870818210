import * as styles from "./paragraph.skeleton.styles";
import { Skeleton } from "./skeleton.js";

interface ParagraphSkeletonProps {
  numLines?: number;
}

export const ParagraphSkeleton = ({ numLines = 2 }: ParagraphSkeletonProps) => {
  return (
    <div>
      {Array.from({ length: numLines }).map((_, i) => (
        <div
          key={i}
          className={styles.line}
          style={{ width: i === numLines - 1 ? "120px" : "100%" }}
        >
          <Skeleton className={styles.skeleton} />
        </div>
      ))}
    </div>
  );
};
