import { EventEmitter } from "../client/event-emitter.js";
import { getPrivacyFunctions, } from "../client/privacy-options.js";
import { formatError } from "../utils/format-error.js";
import { redactEvent } from "./redact-events.js";
export class EventRecorder extends EventEmitter {
    privacyFunctions;
    constructor(props = {}) {
        super();
        this.privacyFunctions = props.privacyFunctions ?? getPrivacyFunctions();
    }
    recordEvent = (event) => {
        const captured = redactEvent(event, this.privacyFunctions);
        if ("extra" in captured) {
            const { extra: _extra, ...rest } = captured;
            this.emit("event", rest);
        }
        else {
            this.emit("event", captured);
        }
    };
    recordInternalError = (error) => {
        if (error instanceof Error) {
            this.emit("internal-error", formatError(error));
        }
    };
    start() {
        throw new Error("Method not implemented.");
    }
    stop() {
        throw new Error("Method not implemented.");
    }
}
