const hasProp = Object.prototype.hasOwnProperty;
function throwsMessage(error) {
    return "[Throws: " + (error instanceof Error ? error.message : "?") + "]";
}
function safeGetValue(obj, property) {
    if (hasProp.call(obj, property)) {
        try {
            return obj[property];
        }
        catch (err) {
            return throwsMessage(err);
        }
    }
    return obj[property];
}
export function toJSON(obj, opts = {}) {
    const seen = [];
    const maxDepth = opts.maxDepth ?? 20;
    const maxStringLength = opts.maxStringLength ?? 100000;
    const maxSize = opts.maxSize ?? 500000;
    let currentDepth = 0;
    let currentSize = 0;
    let sizeLimitReached = false;
    function visit(obj) {
        if (currentSize > maxSize) {
            sizeLimitReached = true;
            return false;
        }
        if (currentDepth > maxDepth) {
            return "[Depth limit reached]";
        }
        if (obj === null ||
            typeof obj === "boolean" ||
            typeof obj === "number" ||
            typeof obj === "string") {
            const size = JSON.stringify(obj).length;
            currentSize += size;
            if (typeof obj === "string" && size > maxStringLength) {
                return (obj.substring(0, maxStringLength) +
                    " [Output truncated due to length]");
            }
            return obj;
        }
        if (seen.indexOf(obj) !== -1) {
            return "[Circular]";
        }
        seen.push(obj);
        if (Array.isArray(obj)) {
            const aResult = obj.map(visit);
            seen.pop();
            return aResult;
        }
        if (typeof obj === "object") {
            currentDepth++;
            if ("toJSON" in obj && typeof obj.toJSON === "function") {
                try {
                    const fResult = visit(obj.toJSON());
                    seen.pop();
                    return fResult;
                }
                catch (err) {
                    return throwsMessage(err);
                }
            }
            const result = Object.keys(obj).reduce(function (result, prop) {
                result[prop] = visit(safeGetValue(obj, prop));
                return result;
            }, {});
            seen.pop();
            return result;
        }
        if (typeof obj === "function") {
            return "[Function]";
        }
        return "[Unknown]";
    }
    const output = visit(obj);
    return sizeLimitReached ? "[Object is too large]" : output;
}
