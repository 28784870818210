import { SessionEventRecorder } from "../events/session-event-recorder.js";
import { regexToString } from "../utils/regex-to-string.js";
import { uniqueId } from "../utils/unique-id.js";
import { WidgetController } from "../widget/widget-controller.js";
import { EventEmitter } from "./event-emitter.js";
import { getPrivacyFunctions } from "./privacy-options.js";
export class CaptureClient extends EventEmitter {
    captureKey;
    graphQLEndpoint;
    customFields = {};
    recorder;
    widgetPosition;
    widgetBaseUrl;
    isExtension;
    captureScreenshotFn;
    isTriggerKeyEnabled;
    isEnabled = true;
    widget = new WidgetController(this);
    constructor(options) {
        super();
        this.captureKey = options.captureKey;
        this.isExtension = options.isExtension ?? false;
        if (options.graphQLEndpoint !== undefined) {
            this.graphQLEndpoint = regexToString(options.graphQLEndpoint);
        }
        this.isTriggerKeyEnabled = options.isTriggerKeyEnabled ?? true;
        this.isEnabled = options.isEnabled ?? true;
        this.widgetPosition = options.widgetPosition ?? "bottom-right";
        this.widgetBaseUrl =
            options.widgetBaseUrl || "https://app.capture.dev/widget";
        this.captureScreenshotFn = options.captureScreenshotFn;
        const privacyFunctions = getPrivacyFunctions(options.privacyOptions);
        const isDisabled = typeof window === "undefined" || navigator.webdriver;
        this.recorder = new SessionEventRecorder({
            eventRecorders: options.eventRecorders,
            startImmediately: !isDisabled,
            privacyFunctions,
        });
        if (isDisabled) {
            return;
        }
        this.customFields = options.customFields || {};
        if (this.isTriggerKeyEnabled && window.parent === window) {
            window.addEventListener("keydown", this.onKeyDown, true);
        }
    }
    open() {
        const isEnabled = typeof this.isEnabled === "function" ? this.isEnabled() : this.isEnabled;
        if (!isEnabled) {
            return;
        }
        this.recorder.recordEvent({
            id: uniqueId(),
            type: "open-widget",
            time: Date.now(),
        });
        this.widget.open();
    }
    close() {
        this.recorder.recordEvent({
            id: uniqueId(),
            type: "close-widget",
            time: Date.now(),
        });
        this.widget.close();
    }
    async captureScreenshot() {
        if (this.captureScreenshotFn) {
            return await this.captureScreenshotFn?.();
        }
        else {
            throw new Error(`Attempted to call captureScreenshot in a context where it is not available`);
        }
    }
    onKeyDown = (event) => {
        if ((event.metaKey || event.ctrlKey) && event.key === "/") {
            this.recorder.clearTriggerKeyEvents();
            this.open();
        }
    };
}
