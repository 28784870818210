import { css } from "@emotion/css";
import { space } from "@styles";

export const root = css`
  display: flex;
  gap: ${space(3)};
`;

export const content = css`
  flex: 1 1 auto;
`;

export const actions = css`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: ${space(2)};
`;
