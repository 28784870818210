import { z } from "zod";

export const ProjectName = z.string().min(2).max(42);

export const UrlOrigin = z.string().regex(/^[A-Za-z0-9-.*]+\.\w+(:\d+)?$/, {
  message:
    'Invalid URL. Include the domain origin without the protocol or path.  For example, "example.com" or "subdomain.example.com".',
});

export const CreateProjectFormValues = z.object({
  name: ProjectName,
  allowedOrigins: z
    .array(UrlOrigin)
    .max(10, { message: "You may only provide up to 10 domains." })
    .min(1, { message: "Please provide at least one domain." }),
});
export type CreateProjectFormValues = z.infer<typeof CreateProjectFormValues>;

export const UpdateProjectFormValues = z.object({
  id: z.string(),
  name: ProjectName.optional(),
  allowedOrigins: z.array(UrlOrigin).max(10).min(1).optional(),
});
export type UpdateProjectFormValues = z.infer<typeof UpdateProjectFormValues>;
