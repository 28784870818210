import z from "zod";

export const TeamName = z.string().max(30);

export const TeamRole = z.enum(["admin", "editor"]);
export type TeamRole = z.infer<typeof TeamRole>;

export const CreateTeamFormValues = z.object({
  name: TeamName,
});
export type CreateTeamFormValues = z.infer<typeof CreateTeamFormValues>;

export const InviteTeamMemberFormValues = z.object({
  email: z.string().email(),
  role: TeamRole,
});
export type InviteTeamMemberFormValues = z.infer<
  typeof InviteTeamMemberFormValues
>;

export const UpdateTeamMemberFormValues = z.object({
  id: z.string(),
  role: TeamRole.optional(),
});
export type UpdateTeamMemberFormValues = z.infer<
  typeof UpdateTeamMemberFormValues
>;

export const UpdateTeamFormValues = z.object({
  name: TeamName.optional(),
  defaultCanViewWithLink: z.boolean().optional(),
});
export type UpdateTeamFormValues = z.infer<typeof UpdateTeamFormValues>;
