import { uniqueId } from "../../utils/unique-id.js";
import { parseRequestBody } from "./parse-request-body.js";
import { parseResponseBody } from "./parse-response-body.js";
export const patchXhr = (sessionRecorder) => {
    const originalOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function (method, url, async = true, username, password) {
        const xhr = this;
        const request = {
            method,
            headers: {},
        };
        const response = {
            status: 0,
            headers: {},
        };
        const extra = {
            request: {
                body: undefined,
            },
        };
        const event = {
            id: uniqueId(),
            type: "request",
            time: Date.now(),
            startTime: performance.now(),
            initiator: "xhr",
            url: url.toString(),
            request,
            response,
            extra,
        };
        const originalSetRequestHeader = xhr.setRequestHeader.bind(xhr);
        xhr.setRequestHeader = (header, value) => {
            request.headers[header.toLowerCase()] = value;
            return originalSetRequestHeader(header, value);
        };
        const originalSend = xhr.send.bind(xhr);
        xhr.send = (body) => {
            if (body) {
                extra.request.body = body;
                request.body = parseRequestBody(body);
            }
            return originalSend(body);
        };
        xhr.addEventListener("readystatechange", async () => {
            if (xhr.readyState !== xhr.DONE) {
                return;
            }
            event.endTime = performance.now();
            response.status = xhr.status;
            const rawHeaders = xhr.getAllResponseHeaders();
            const headers = rawHeaders.trim().split(/[\r\n]+/);
            headers.forEach((line) => {
                const parts = line.split(": ");
                const header = parts.shift()?.toLowerCase();
                const value = parts.join(": ");
                if (header) {
                    response.headers[header] = value;
                }
            });
            if (xhr.response) {
                try {
                    extra.response = {
                        body: xhr.response,
                    };
                    response.body = await parseResponseBody(xhr.response, response.headers["content-type"]);
                }
                catch (error) {
                    sessionRecorder.recordInternalError(error);
                }
            }
            sessionRecorder.recordEvent(event);
        });
        originalOpen.call(xhr, method, url, async, username, password);
    };
    return () => {
        XMLHttpRequest.prototype.open = originalOpen;
    };
};
