import { css } from "@emotion/css";
import { fontSize, lineHeight } from "@styles";

export const line = css`
  height: calc(${fontSize.level3} * ${lineHeight.level3});
`;

export const skeleton = css`
  height: ${fontSize.level3};
`;
