export const REDACTED = "$REDACTED$";
/* Preset functions */
const isErrorRequest = (event) => event.error !== null || !event.response || event.response.status >= 400;
const requestBodyPresetMap = {
    all: () => true,
    none: () => false,
    "on-error-only": ({ event }) => isErrorRequest(event),
};
const requestHeaderPresetMap = {
    all: ({ key, value }) => [key, value],
    "key-only": ({ key }) => [key, REDACTED],
    none: () => [REDACTED, REDACTED],
    "on-error-only": ({ event, key, value }) => isErrorRequest(event) ? [key, value] : [REDACTED, REDACTED],
};
const elementNamePresetMap = {
    auto: ({ name }) => name,
    "tag-only": ({ element }) => element.tagName.toLowerCase(),
};
const elementAttributePresetMap = {
    all: ({ key, value }) => [key, value],
    "key-only": ({ key }) => [key, REDACTED],
};
const inputValuePresetMap = {
    all: () => true,
    none: () => false,
};
export const getPrivacyFunctions = ({ requestBody, responseBody, requestHeader, responseHeader, elementName, elementAttribute, inputValue, } = {}) => {
    return {
        responseBody: typeof responseBody === "function"
            ? responseBody
            : requestBodyPresetMap[responseBody ?? "all"],
        requestBody: typeof requestBody === "function"
            ? requestBody
            : requestBodyPresetMap[requestBody ?? "all"],
        requestHeader: typeof requestHeader === "function"
            ? requestHeader
            : requestHeaderPresetMap[requestHeader ?? "all"],
        responseHeader: typeof responseHeader === "function"
            ? responseHeader
            : requestHeaderPresetMap[responseHeader ?? "all"],
        elementName: typeof elementName === "function"
            ? elementName
            : elementNamePresetMap[elementName ?? "auto"],
        elementAttribute: typeof elementAttribute === "function"
            ? elementAttribute
            : elementAttributePresetMap[elementAttribute ?? "all"],
        inputValue: typeof inputValue === "function"
            ? inputValue
            : inputValuePresetMap[inputValue ?? "all"],
    };
};
