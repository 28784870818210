import { z } from "zod";

export const OnboardingStage = z.enum(["channel", "role", "companySize"]);
export type OnboardingStage = z.infer<typeof OnboardingStage>;

export const ProfileJobRole = z.enum([
  "developer",
  "support",
  "founder",
  "product_management",
  "designer",
  "operations",
  "freelancer",
  "other",
  "qa",
  "undisclosed",
]);

export type ProfileJobRole = z.infer<typeof ProfileJobRole>;

export const ProfileChannel = z.enum([
  "google_search",
  "recommendation",
  "online_ad",
  "social_media",
  "other",
]);
export type ProfileChannel = z.infer<typeof ProfileChannel>;

export const ProfileCompanySize = z.enum([
  "1",
  "1-9",
  "10-24",
  "25-49",
  "50-99",
  "100",
]);
export type ProfileCompanySize = z.infer<typeof ProfileCompanySize>;

export const UpdateUserInput = z.object({
  jobRole: ProfileJobRole.optional(),
  companySize: ProfileCompanySize.optional(),
  channel: ProfileChannel.optional(),
  termsAccepted: z.boolean().optional(),
  fullName: z.string().optional(),
  preferredName: z.string().optional(),
});
export type UpdateUserInput = z.infer<typeof UpdateUserInput>;
