import { cx } from "@emotion/css";
import React from "react";

import * as styles from "./skeleton.styles";

export interface SkeletonProps extends React.HTMLAttributes<HTMLDivElement> {
  stretchToFill?: boolean;
}

export const Skeleton = ({
  stretchToFill,
  children,
  ...rest
}: SkeletonProps) => {
  return (
    <div
      {...rest}
      aria-hidden
      className={cx(rest.className, styles.root, {
        [styles.stretchToFill]: stretchToFill,
      })}
    >
      {children}
    </div>
  );
};
