import { z } from "zod";

import { CapturedEvent } from "./captured-event.js";
import { ParsedConsoleEvent } from "./console-event.js";
import { ErrorInfo } from "./error-info.js";
import { InteractionEvent } from "./interaction-event.js";
import { ParsedNetworkEvent } from "./network-event.js";

export const S3PresignedPost = z.object({
  url: z.string(),
  fields: z.record(z.string()),
});
export type S3PresignedPost = z.infer<typeof S3PresignedPost>;

export const ScreenRecordingFormat = z.enum(["webm", "mp4"]);
export type ScreenRecordingFormat = z.infer<typeof ScreenRecordingFormat>;

export const ReportEvent = z.union([
  ParsedNetworkEvent,
  ParsedConsoleEvent,
  InteractionEvent,
]);
export type ReportEvent = z.infer<typeof ReportEvent>;

export const CreateSnapshotAnnotationInput = z.object({
  width: z.number(),
  height: z.number(),
  snapshotWidth: z.number(),
  snapshotHeight: z.number(),
});
export type CreateSnapshotAnnotationInput = z.infer<
  typeof CreateSnapshotAnnotationInput
>;

export const CreateSnapshotInput = z.object({
  events: z.array(CapturedEvent),
  viewportWidth: z.number(),
  viewportHeight: z.number(),
  cropX: z.number(),
  cropY: z.number(),
  cropWidth: z.number(),
  cropHeight: z.number(),
  annotation: CreateSnapshotAnnotationInput.optional(),
  width: z.number().optional(),
  height: z.number().optional(),
});
export type CreateSnapshotInput = z.infer<typeof CreateSnapshotInput>;

export const CreateScreenRecordingInput = z.object({
  events: z.array(CapturedEvent),
  startTime: z.number(),
  endTime: z.number(),
  format: ScreenRecordingFormat,
});
export type CreateScreenRecordingInput = z.infer<
  typeof CreateScreenRecordingInput
>;

export const CreateReportInput = z.object({
  captureKey: z.string().optional(),
  description: z.string(),
  url: z.string(),
  sdkVersion: z.string(),
  userAgent: z.string(),
  pageTitle: z.string(),
  pageDescription: z.string().optional(),
  browserOverride: z.string().optional(),
  browserTimeZone: z.string(),
  browserLanguage: z.string(),
  internalErrors: z.array(ErrorInfo),
  customFields: z.record(
    z.union([z.string(), z.number(), z.boolean(), z.null(), z.undefined()])
  ),
  graphQLEndpoint: z.string().optional(),
  snapshot: CreateSnapshotInput.optional(),
  screenRecording: CreateScreenRecordingInput.optional(),
});
export type CreateReportInput = z.infer<typeof CreateReportInput>;

export const CreateReportOutput = z.object({
  id: z.string(),
  video: S3PresignedPost.nullable(),
  snapshot: S3PresignedPost.nullable(),
  annotation: S3PresignedPost.nullable(),
  screenshot: S3PresignedPost.nullable(),
  thumb: S3PresignedPost.nullable(),
});
export type CreateReportOutput = z.infer<typeof CreateReportOutput>;

export const ReportUploadCompleteParams = z.object({
  reportId: z.string(),
});
export type ReportUploadCompleteParams = z.infer<
  typeof ReportUploadCompleteParams
>;
