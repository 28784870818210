import { Skeleton } from "@components/skeleton/skeleton.js";
import { PageLayoutSkeleton } from "@features/page-layout/page-layout.skeleton.js";

import * as styles from "./page-loader.styles";

export const PageLoader = () => {
  return (
    <PageLayoutSkeleton title="Page loading">
      <Skeleton className={styles.blockOne} />
    </PageLayoutSkeleton>
  );
};
