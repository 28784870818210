import { css } from "@emotion/css";
import { color, space } from "@styles";

export const root = css`
  background: ${color("primary")};
  border: solid 1px ${color("primaryBorder")};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${space(3)};
`;

export const title = css`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr auto;
  gap: ${space(1)};
  margin-bottom: ${space(2)};

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const content = css`
  flex-grow: 1;
`;

export const footer = css`
  margin-top: ${space(3)};
  flex: 0 0 auto;
`;
